import { auth, firestore } from "../firebase";
import NativeCategoriesApi from "./native-categories";

export interface Favorite {
  description: string;
  label: string;
  placeId: string;
  id: string;
  location: { lat: number; lng: number };
  icon: string | null;
  friends: Array<string>;
}

export const RestaurantTypes = ["meal_delivery", "meal_takeaway", "restaurant"];
export const BarTypes = ["bar", "casino", "night_club", "liquor_store"];
export const OutdoorTypes = [
  "amusement_park",
  "aquarium",
  "bowling_alley",
  "campground",
  "gym",
  "park",
  "rv_park",
  "zoo",
];
export const EventTypes = ["stadium", "university"];
export const ShoppingTypes = [
  "bicycle_store",
  "book_store",
  "clothing_store",
  "convenience_store",
  "department_store",
  "drugstore",
  "electronics_store",
  "florist",
  "furniture_store",
  "gas_station",
  "grocery_or_supermarket",
  "hardware_store",
  "home_goods_store",
  "jewelry_store",
  "lodging",
  "pet_store",
  "pharmacy",
  "shoe_store",
  "shopping_mall",
  "store",
  "supermarket",
];
export const ArtTypes = [
  "art_gallery",
  "museum",
  "movie_theater",
  "movie_rental",
];
export const CafeTypes = ["bakery", "cafe"];
export const SightseeingTypes = [
  "church",
  "city_hall",
  "embassy",
  "hindu_temple",
  "library",
  "light_rail_station",
  "local_government_office",
  "mosque",
  "synagogue",
  "tourist_attraction",
  "train_station",
  "transit_station",
];
export const PersonalCareTypes = [
  "beauty_salon",
  "hair_care",
  "physiotherapist",
  "spa",
];
export const ServicesTypes = [
  "airport",
  "accounting",
  "atm",
  "bank",
  "bus_station",
  "car_dealer",
  "car_rental",
  "car_repair",
  "car_wash",
  "cemetery",
  "courthouse",
  "dentist",
  "doctor",
  "electrician",
  "fire_station",
  "funeral_home",
  "hospital",
  "insurance_agency",
  "laundry",
  "lawyer",
  "locksmith",
  "moving_company",
  "painter",
  "parking",
  "plumber",
  "police",
  "post_office",
  "primary_school",
  "real_estate_agency",
  "roofing_contractor",
  "school",
  "secondary_school",
  "storage",
  "subway_station",
  "taxi_stand",
  "travel_agency",
  "veterinary_care",
];

const PlacesApi = {
  setFavoriteForUser: (fav: CustomPlace) => {
    return new Promise<null>((resolve, reject) => {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        return;
      }
      const uid = currentUser.uid;
      if (!uid) {
        return;
      }

      const reference = firestore
        .collection("users")
        .doc(uid)
        .collection("favorites")
        .doc(fav.id);

      fav = JSON.parse(
        JSON.stringify(fav, function (k, v) {
          if (v === undefined) {
            return null;
          }
          return v;
        })
      );

      reference
        .set(fav)
        .then(() => {
          resolve();
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  removeFavoriteForUser: (favId: string) => {
    return new Promise<null>((resolve, reject) => {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        reject();

        return;
      }
      const uid = currentUser.uid;
      if (!uid) {
        reject();

        return;
      }
      firestore
        .collection("users")
        .doc(uid)
        .collection("favorites")
        .doc(favId)
        .delete()
        .then(() => {
          resolve();
        });
    });
  },

  getFavoritesList: () => {
    return new Promise<CustomPlace[]>(async (resolve, reject) => {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        reject();

        return;
      }
      const uid = currentUser.uid;
      if (!uid) {
        reject();

        return;
      }

      const userData = await firestore.collection("users").doc(uid).get();
      const userCats = userData.data()?.categoryGroups as Array<string>;

      const reference = firestore
        .collection("users")
        .doc(uid)
        .collection("favorites");

      //need to get the confirmed friends and get their favorites, maybe make them a different color
      const friendsReference = firestore.collection("users").doc(uid);

      let friendRefs = await friendsReference.get().then((friendsSnapshot) => {
        let data = friendsSnapshot.data();
        if (!data) {
          return [] as firebase.firestore.DocumentReference[];
        }
        let ids = data.following as string[];

        if (!ids) {
          return [] as firebase.firestore.DocumentReference[];
        }

        return ids.map((id) => {
          return firestore.collection("users").doc(id);
        });
      });

      let friendPromises = friendRefs.map((ref) => {
        return ref
          .collection("favorites")
          .get()
          .then((friendFavsSnapshot) => {
            let favorites = friendFavsSnapshot.docs.map((doc) => {
              return {
                ...doc.data(),
                id: doc.id,
                friends: [ref.id],
                icon: "/images/friends-location-pin.svg",
              } as CustomPlace;
            });
            return favorites;
          });
      });

      let myPromise = reference.get().then((favoritesSnapshot) => {
        let favorites = favoritesSnapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
            friends: [uid],
            icon: "/images/favorite-location-pin.svg",
          } as CustomPlace;
        });
        return favorites;
      });

      Promise.all([...friendPromises, myPromise]).then((favsArray) => {
        const favs = {} as { [id: string]: CustomPlace };
        const allFavs = favsArray.flat(1);

        allFavs.forEach((fav) => {
          let needToAdd = false;
          if (fav.types) {
            let types = fav.types;
            if (!userCats || userCats.length > 9 || userCats.length === 0) {
              needToAdd = true;
            } else {
              needToAdd = types.some((type) => {
                return userCats.some((cat) => {
                  switch (cat) {
                    case "events":
                      return EventTypes.includes(type);
                    case "dining":
                      return RestaurantTypes.includes(type);
                    case "localCafe":
                      return CafeTypes.includes(type);
                    case "personal":
                      return PersonalCareTypes.includes(type);
                    case "sightseeing":
                      return SightseeingTypes.includes(type);
                    case "services":
                      return ServicesTypes.includes(type);
                    case "bars":
                      return BarTypes.includes(type);
                    case "movies":
                      return ArtTypes.includes(type);
                    case "shopping":
                      return ShoppingTypes.includes(type);
                    case "outdoor":
                      return OutdoorTypes.includes(type);
                    default:
                      return false;
                  }
                });
              });

              types.forEach((type) => {
                userCats.forEach((cat) => {});
              });
            }
          }
          if (needToAdd) {
            let oldFav = favs[fav.id];
            if (oldFav) {
              oldFav.friends = [
                ...(oldFav.friends ? oldFav.friends : []),
                ...(fav.friends ? fav.friends : []),
              ];
              favs[fav.id] = oldFav;
            } else {
              favs[fav.id] = fav;
            }
          }
        });

        resolve(Object.values(favs));
      });
    });
  },

  getNativePlacesList: (latitude: number, longitude: number) => {
    return new Promise<CustomPlace[]>(async (resolve, reject) => {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        reject();

        return;
      }

      const uid = currentUser.uid;
      if (!uid) {
        reject();

        return;
      }

      const userData = await firestore.collection("users").doc(uid).get();
      const userCats = userData.data()?.categoryGroups as Array<string>;

      const userNativeGroups = await NativeCategoriesApi.getNativeCategoryGroupsForCurrentUser();

      const reference = firestore.collection("users");

      reference.get().then((usersSnap) => {
        const favPromises: Array<Promise<any>> = [];

        usersSnap.docs.forEach((user) => {
          if (user.id === uid) {
            return;
          }

          const otherNativeCatGroups = user.data().nativeCategoryGroups;

          if (!otherNativeCatGroups || otherNativeCatGroups.length < 3) {
            return;
          }

          let count = 0;
          otherNativeCatGroups.forEach((group: string) => {
            if (userNativeGroups && userNativeGroups.includes(group)) {
              count++;
            }
          });
          if (count < 3) {
            return;
          }

          favPromises.push(
            user.ref
              .collection("favorites")
              .get()
              .then((snapshot) => {
                return snapshot.docs.map((doc) => {
                  return {
                    ...doc.data(),
                    id: doc.id,
                    friends: [user.id],
                  } as CustomPlace;
                });
              })
          );
        });

        Promise.all(favPromises).then((results) => {
          const favs = {} as { [id: string]: CustomPlace };

          results.flat(1).forEach((result: CustomPlace) => {
            //approx 1 mile in distance
            let lat = 0.0144927536231884;
            let lon = 0.0181818181818182;
            let distance = 300; //miles

            let lowerLat = latitude - lat * distance;
            let lowerLon = longitude - lon * distance;

            let greaterLat = latitude + lat * distance;
            let greaterLon = longitude + lon * distance;
            if (
              result &&
              result.position &&
              result.position.lat &&
              result.position.lng
            ) {
              if (
                greaterLon > result.position.lng &&
                lowerLon < result.position.lng &&
                greaterLat > result.position.lat &&
                lowerLat < result.position.lat
              ) {
                result.icon = "/images/native-location-pin.svg";
                let types: string[] = result.types ? result.types : [];
                let needToAdd = false;
                if (!userCats || userCats.length > 9 || userCats.length === 0) {
                  needToAdd = true;
                } else {
                  needToAdd = types.some((type) => {
                    return userCats.some((cat) => {
                      switch (cat) {
                        case "events":
                          return EventTypes.includes(type);
                        case "dining":
                          return RestaurantTypes.includes(type);
                        case "localCafe":
                          return CafeTypes.includes(type);
                        case "personal":
                          return PersonalCareTypes.includes(type);
                        case "sightseeing":
                          return SightseeingTypes.includes(type);
                        case "services":
                          return ServicesTypes.includes(type);
                        case "bars":
                          return BarTypes.includes(type);
                        case "movies":
                          return ArtTypes.includes(type);
                        case "shopping":
                          return ShoppingTypes.includes(type);
                        case "outdoor":
                          return OutdoorTypes.includes(type);
                        default:
                          return false;
                      }
                    });
                  });
                }
                if (needToAdd) {
                  let current = favs[result.id];
                  if (current && current.friends) {
                    result.friends = result.friends
                      ? [...result.friends, ...current.friends]
                      : current.friends;
                  }
                  favs[result.id] = result;
                }
              }
            }
          });

          resolve(Object.values(favs));
        });
      });
    });
  },

  getPlaceFromId: (placeId: string) => {
    return new Promise<CustomPlace>((resolve, reject) => {
      let div = document.createElement("div");
      div.hidden = true;
      let map = new google.maps.Map(div);
      let places = new google.maps.places.PlacesService(map);
      let request = {
        placeId,
        fields: [
          "name",
          "opening_hours",
          "formatted_address",
          "formatted_phone_number",
          "website",
          "place_id",
          "price_level",
          "geometry",
          "rating",
          "types",
        ],
      } as google.maps.places.PlaceDetailsRequest;
      let callback = (result: google.maps.places.PlaceResult) => {
        let openin = result.opening_hours
          ? result.opening_hours
          : ({
              isOpen: (date?: Date | undefined) => false,
              open_now: false,
              periods: [],
              weekday_text: [],
            } as google.maps.places.OpeningHours);
        let { isOpen, ...hours } = openin;
        let placeResult = {
          name: result.name,
          address: result.formatted_address,
          phoneNumber: result.formatted_phone_number,
          id: result.place_id,
          website: result.website,
          hours: hours,
          types: result.types,
          position: {
            lat: result.geometry?.location.lat(),
            lng: result.geometry?.location.lng(),
          },
          icon: "/images/temp-location-marker.svg",
        } as CustomPlace;
        resolve(placeResult);
      };
      places.getDetails(request, callback);
    });
  },
};

export interface OpeningHours
  extends Omit<google.maps.places.OpeningHours, "isOpen"> {}

export interface CustomPlace {
  name: string;
  address: string;
  phoneNumber: string;
  id: string;
  website: string;
  hours: OpeningHours;
  friends?: Array<string>;
  types?: Array<string>;
  position: {
    lat: number;
    lng: number;
  };
  icon: string;
}

export default PlacesApi;
