import { default as React, Fragment } from "react";
import { CustomPlace } from "../../services/places";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Box,
  Typography,
  IconButton,
  ExpansionPanelDetails,
  Divider,
  Link,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import FavoriteIcon from "../../icons/FavoriteIcon";
import PlaceHours from "../PlaceHours";
import moment from "moment";
import CardLocationIcon from "../../icons/CardLocationIcon";
import CardPhoneIcon from "../../icons/CardPhoneIcon";
import CardWebsiteIcon from "../../icons/CardWebsiteIcon";
import CardClockIcon from "../../icons/CardClockIcon";
import FriendSection from "../FriendSection";

export type PlaceCardProps = {
  place: CustomPlace;
  isFavorite: boolean;
  setFavorite: Function;
};

const styles = (theme: Theme) =>
  createStyles({
    panel: {
      margin: "-25px 0 0 0",
      borderRadius: "15px 15px 0 0",
      "&$expanded": { margin: "-25px 0 0 0", borderRadius: "15px 15px 0 0" },
    },
    expanded: {},
    details: {
      // alignItems: "center",
      flexDirection: "column",
      padding: "unset",
    },
    detailText: {
      fontSize: theme.typography.pxToRem(12),
      padding: theme.spacing(1),
      color: theme.palette.text.secondary,
    },
    peopleText: {
      fontSize: theme.typography.pxToRem(14),
      fontFamily: "EuropaBold",
      padding: "12px",
      color: theme.palette.text.secondary,
    },
    divider: {
      width: "100%",
    },
    heading: {
      fontFamily: "EuropaBold",
      fontSize: theme.typography.pxToRem(15),
    },
    summary: {
      width: "100%",
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
    },
    favoriteIcon: {
      paddingRight: "24px",
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    icon: {
      verticalAlign: "bottom",
      height: 20,
      width: 20,
    },
    openText: {
      fontSize: theme.typography.pxToRem(12),
      color: theme.palette.text.secondary,
    },
    row: {
      display: "flex",
      alignItems: "center",
      marginLeft: theme.spacing(1),
    },
  });

const useStyles = makeStyles(styles);

export const PlaceCard: React.FC<PlaceCardProps> = ({
  place,
  isFavorite,
  setFavorite,
}) => {
  const classes = useStyles();

  let isOpen = false;
  let todaysClose: moment.Moment | null = null;
  let nextOpen: moment.Moment | null = null;

  if (place && place.hours && place.hours.periods.length > 0) {
    const now = moment();
    const times = place.hours;

    const todaysOpeningPeriod = times.periods.find(
      (period) => period.open.day === now.day()
    );
    let nextOpeningPeriod;

    for (let i = 0; i++; i < times.periods.length) {
      if (times.periods[(now.day() + 1 + i) % 7]) {
        nextOpeningPeriod = times.periods[(now.day() + 1 + i) % 7];
        break;
      }
    }

    const todaysOpen = todaysOpeningPeriod
      ? moment(todaysOpeningPeriod.open.time, "HHmm")
      : null;
    todaysClose =
      todaysOpeningPeriod && todaysOpeningPeriod.close
        ? moment(todaysOpeningPeriod.close.time, "HHmm")
        : null;
    if (todaysClose?.isBefore(todaysOpen as moment.Moment)) {
      todaysClose.add(1, "day");
    }
    nextOpen = nextOpeningPeriod
      ? moment(nextOpeningPeriod.open.nextDate, "HHmm")
      : null;
    isOpen = todaysOpen
      ? todaysClose
        ? now.isBetween(todaysOpen, todaysClose)
        : now.isAfter(todaysOpen)
      : false;
  }

  return (
    <ExpansionPanel
      classes={{ root: classes.panel, expanded: classes.expanded }}
    >
      <ExpansionPanelSummary
        aria-controls="panel1c-content"
        id="panel1c-header"
      >
        <div className={classes.summary}>
          <Box flex={1}>
            <Typography variant={"h3"} className={classes.heading}>
              {place.name}
            </Typography>
            {place.hours && place.hours.periods.length > 0 ? (
              isOpen ? (
                <Fragment>
                  <Typography
                    style={{ display: "inline-block", color: "#109D57" }}
                    variant={"body1"}
                    className={classes.openText}
                  >
                    Open
                  </Typography>
                  <Typography
                    style={{ display: "inline-block" }}
                    variant={"body1"}
                    className={classes.openText}
                  >
                    {todaysClose
                      ? "· Closes " + todaysClose.format("h:mm a")
                      : ""}
                  </Typography>
                </Fragment>
              ) : (
                <Fragment>
                  <Typography
                    style={{ display: "inline-block", color: "#DE463C" }}
                    variant={"body1"}
                    className={classes.openText}
                  >
                    Closed
                  </Typography>
                  <Typography
                    style={{ display: "inline-block" }}
                    variant={"body1"}
                    className={classes.openText}
                  >
                    {nextOpen ? "· Opens " + nextOpen.format("h:mm a") : ""}
                  </Typography>
                </Fragment>
              )
            ) : null}
          </Box>
          <IconButton
            onClick={(
              event: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => {
              event.stopPropagation();
              setFavorite();
            }}
            className={classes.favoriteIcon}
          >
            <FavoriteIcon
              solid={isFavorite}
              color={isFavorite ? "secondary" : undefined}
            />
          </IconButton>
        </div>
        {}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        <Divider className={classes.divider} />
        {place.address ? (
          <div className={classes.row}>
            <CardLocationIcon style={{ color: "#247ba0" }} />
            <Typography className={classes.detailText}>
              {place.address}
            </Typography>
          </div>
        ) : null}
        {place.hours && place.hours.periods.length > 0 ? (
          <div className={classes.row}>
            <CardClockIcon style={{ color: "#247ba0" }} />
            <PlaceHours times={place.hours} />
          </div>
        ) : null}
        <Divider className={classes.divider} />
        {place.phoneNumber ? (
          <>
            <div className={classes.row}>
              <CardPhoneIcon style={{ color: "#247ba0" }} />
              <Typography className={classes.detailText}>
                {place.phoneNumber}
              </Typography>
            </div>
            <Divider className={classes.divider} />
          </>
        ) : null}
        {place.website ? (
          <>
            <div className={classes.row}>
              <CardWebsiteIcon style={{ color: "#247ba0" }} />
              <Typography className={classes.detailText}>
                <Link
                  href={new URL(place.website).href}
                  color="inherit"
                  target="_blank"
                  rel="noopener"
                >
                  {new URL(place.website).hostname}
                </Link>
              </Typography>
            </div>
            <Divider className={classes.divider} />
            {
              <>
                <Typography variant="h5" className={classes.peopleText}>
                  People Like You Visiting
                </Typography>
                {place.friends ? (
                  <FriendSection friendIds={place.friends} />
                ) : null}
              </>
            }
          </>
        ) : null}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default PlaceCard;
