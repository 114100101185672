import { default as firebase, auth, firestore } from "../firebase";

export interface NativeCategory {
  displayName: string;
  name: string;
  id: string;
  selected: boolean;
}

const NativeCategoriesApi = {
  getNativeCategoryGroupsForCurrentUser: () => {
    return new Promise<Array<string>>((resolve, reject) => {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        reject();

        return;
      }
      const uid = currentUser.uid;
      if (!uid) {
        reject();

        return;
      }
      let reference = firestore.collection("users").doc(uid);
      reference.get().then(snapshot => {
        let data = snapshot.data();
        if (data) {
          const groups = data.nativeCategoryGroups as Array<string>;
          resolve(groups);
        } else {
          reject();
        }
      });
    });
  },

  setNativeCategoryGroupForUser: (name: string, checked: boolean) => {
    return new Promise<null>((resolve, reject) => {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        reject();
        return;
      }
      const uid = currentUser.uid;
      if (!uid) {
        reject();
        return;
      }
      let reference = firestore.collection("users").doc(uid);
      if (checked) {
        reference.set(
          {
            nativeCategoryGroups: firebase.firestore.FieldValue.arrayUnion(name)
          },
          { merge: true }
        );
        resolve();
      } else {
        reference.set(
          {
            nativeCategoryGroups: firebase.firestore.FieldValue.arrayRemove(
              name
            )
          },
          { merge: true }
        );
        resolve();
      }
    });
  },

  setNativeCategoryForUser: (catID: string, checked: boolean) => {
    return new Promise<null>((resolve, reject) => {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        reject();

        return;
      }
      const uid = currentUser.uid;
      if (!uid) {
        reject();

        return;
      }
      let reference = firestore
        .collection("users")
        .doc(uid)
        .collection("nativeCategories");
      if (checked) {
        reference.doc(catID).set({
          nativeCategory: firestore.collection("nativeCategories").doc(catID)
        });
      } else {
        reference.doc(catID).delete();
        resolve();
      }
    });
  },

  getNativeCategoriesList: () => {
    return new Promise<NativeCategory[]>((resolve, reject) => {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        reject();

        return;
      }
      const uid = currentUser.uid;
      if (!uid) {
        reject();

        return;
      }
      firestore
        .collection("nativeCategories")
        .get()
        .then(nativeCategoriesSnapshot => {
          let nativeCategories = nativeCategoriesSnapshot.docs.map(doc => {
            return {
              ...doc.data(),
              id: doc.id
            } as NativeCategory;
          });
          return nativeCategories;
        })
        .then(async nativeCategories => {
          return await firestore
            .collection("users")
            .doc(uid)
            .collection("nativeCategories")
            .get()
            .then(catSnapshot => {
              const userCatIDs = catSnapshot.docs.map(doc => doc.id);
              return nativeCategories.map(cat => {
                return {
                  ...cat,
                  selected: userCatIDs.includes(cat.id)
                };
              });
            });
        })
        .then(nativeCategories => {
          resolve(nativeCategories);
        })
        .catch(e => {
          reject(e);
        });
    });
  }
};

export default NativeCategoriesApi;
