import { createContext } from "react";

const CenterContext = createContext(
  {} as {
    center: { lat: number; lng: number };
    setCenter: (center: { lat: number; lng: number }) => void;
  }
);

export const CenterProvider = CenterContext.Provider;
export const CenterConsumer = CenterContext.Consumer;

export default CenterContext;
