import React from "react";
import { DialogContent, Box, Typography } from "@material-ui/core";
import NativeCategoriesGroup from "../NativeCategoriesGroup";

const CategoriesTab: React.FC = () => {
  return (
    <DialogContent>
      <Box height="50px"></Box>
      <Typography
        style={{ fontFamily: "EuropaBold" }}
        align="center"
        variant="subtitle2"
      >
        Choose You Favorite Location Preferences
      </Typography>
      <Typography align="center" variant="body2">
        This will help you search what matters to you most
      </Typography>
      <Box height="50px"></Box>
      <NativeCategoriesGroup />
    </DialogContent>
  );
};

export default CategoriesTab;
