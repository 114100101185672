import { createContext } from "react";

const SelectedUserContext = createContext(
  {} as {
    selectedUserId: string | false;
    setSelectedUserId: (selectedUser: string | false) => void;
  }
);

export const SelectedUserProvider = SelectedUserContext.Provider;
export const SelectedUserConsumer = SelectedUserContext.Consumer;

export default SelectedUserContext;
