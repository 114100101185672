import React, { useEffect, useState } from "react";
import { DialogContent, Tabs, Tab } from "@material-ui/core";
import { default as UserService, UserProfile } from "../../services/users";
import UserSearchSection from "../UserSearchSection";

export type UserRowProps = {
  close: Function;
};

const FriendsTab: React.FC<UserRowProps> = ({ close }) => {
  // const classes = useStyles(props);
  const [value, setValue] = React.useState(
    "following" as "followers" | "following" | "all"
  );
  const [refreshed, setRefreshed] = useState(0);
  const [following, setFollowing] = useState([] as Array<string>);
  const [followers, setFollowers] = useState([] as Array<string>);
  const [users, setUsers] = useState([] as Array<UserProfile>);
  useEffect(() => {
    async function getFollowers() {
      let ret = (await UserService.getFollowers()) as Array<string>;
      setFollowers(ret);
    }
    async function getFollowing() {
      let ret = (await UserService.getFollowing()) as Array<string>;
      setFollowing(ret);
    }
    async function getUsers() {
      let ret = (await UserService.getUsers()) as Array<UserProfile>;
      setUsers(ret);
    }
    getFollowers();
    getFollowing();
    getUsers();
  }, [refreshed]);

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: "followers" | "following" | "all"
  ) => {
    setValue(newValue);
  };

  const refresh = () => {
    setRefreshed(prevCount => prevCount + 1);
  };

  return (
    <DialogContent>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="secondary"
        centered
        TabIndicatorProps={{ style: { backgroundColor: "white" } }}
        variant="fullWidth"
      >
        <Tab
          style={{ fontFamily: "EuropaBold" }}
          value="followers"
          label="Followers"
        />
        <Tab
          style={{ fontFamily: "EuropaBold" }}
          value="following"
          label="Following"
        />
        <Tab style={{ fontFamily: "EuropaBold" }} value="all" label="Add" />
      </Tabs>

      <UserSearchSection
        section={value}
        users={users}
        followers={followers}
        following={following}
        refresh={refresh}
        close={close}
      />
    </DialogContent>
  );
};

export default FriendsTab;
