import React, { Component, ChangeEvent } from "react";

import validate from "validate.js";

import { withStyles, Theme, WithStyles } from "@material-ui/core/styles";

import DialogContent from "@material-ui/core/DialogContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import TextField from "@material-ui/core/TextField";

import constraints from "../../constraints";
import authentication from "../../services/authentication";
import { Button } from "@material-ui/core";

const styles = (theme: Theme) => ({
  inputFocused: {
    "& label.Mui-focused": {
      color: "grey",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "grey",
      },
    },
  },
  dialogContent: {
    paddingTop: theme.spacing(2),
  },
});

interface AccountTabProps extends WithStyles<typeof styles> {
  user: {
    photoURL: string;
    email: string;
    emailVerified: boolean;
    metadata: {
      lastSignInTime: number;
    };
  };
  userData: {
    lastName: string;
    firstName: string;
    username: string;
    age: string;
    bio: string;
    location: string;
  };
  openSnackbar: Function;
}

interface AccountTabState {
  profileCompletion: number | null;
  securityRating: number | null;

  showingField: string;

  avatar: null | File;
  avatarUrl: string;
  firstName: string;
  lastName: string;
  username: string;
  age: string;
  bio: string;
  location: string;
  emailAddress: string;

  performingAction: boolean;
  loadingAvatar: boolean;
  sentVerificationEmail: boolean;

  errors: any;
}

const initialState: AccountTabState = {
  profileCompletion: 0,
  securityRating: 0,

  showingField: "",

  avatar: null,
  avatarUrl: "",
  firstName: "",
  lastName: "",
  username: "",
  age: "",
  bio: "",
  location: "",
  emailAddress: "",

  performingAction: false,
  loadingAvatar: false,
  sentVerificationEmail: false,

  errors: null,
};

class AccountTab extends Component<AccountTabProps, AccountTabState> {
  constructor(props: AccountTabProps) {
    super(props);

    this.state = initialState;
  }

  showField = (fieldId: string) => {
    if (!fieldId) {
      return;
    }

    this.setState({
      showingField: fieldId,
    });
  };

  changeFirstName = (firstName: string) => {
    const errors = validate(
      {
        firstName: firstName,
      },
      {
        firstName: constraints.firstName,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });

      return;
    }

    this.setState(
      {
        errors: null,
      },
      () => {
        const { userData } = this.props;

        if (firstName === userData.firstName) {
          return;
        }

        authentication.changeFirstName(firstName).catch((reason) => {
          if (!reason) return;
          const code = reason.code;
          const message = reason.message;

          switch (code) {
            default:
              this.props.openSnackbar(message);
              return;
          }
        });
      }
    );
  };

  changeLastName = (lastName: string) => {
    const errors = validate(
      {
        lastName: lastName,
      },
      {
        lastName: constraints.lastName,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });

      return;
    }

    this.setState(
      {
        errors: null,
      },
      () => {
        const { userData } = this.props;

        if (lastName === userData.lastName) {
          return;
        }

        authentication.changeLastName(lastName).catch((reason) => {
          if (!reason) return;
          const code = reason.code;
          const message = reason.message;

          switch (code) {
            default:
              this.props.openSnackbar(message);
              return;
          }
        });
      }
    );
  };

  changeUsername = (username: string) => {
    const errors = validate(
      {
        username: username,
      },
      {
        username: constraints.username,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });

      return;
    }

    this.setState(
      {
        errors: null,
      },
      () => {
        const { userData } = this.props;

        if (username === userData.username) {
          return;
        }

        authentication.changeUsername(username).catch((reason) => {
          if (!reason) return;
          const code = reason.code;
          const message = reason.message;

          switch (code) {
            default:
              this.props.openSnackbar(message);
              return;
          }
        });
      }
    );
  };

  changeAge = (age: string) => {
    const errors = validate(
      {
        age: age,
      },
      {
        age: constraints.age,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });

      return;
    }

    this.setState(
      {
        errors: null,
      },
      () => {
        const { userData } = this.props;

        if (age === userData.age) {
          return;
        }

        authentication.changeAge(age);
      }
    );
  };

  changeBio = (bio: string) => {
    const errors = validate(
      {
        bio: bio,
      },
      {
        bio: constraints.bio,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });

      return;
    }

    this.setState(
      {
        errors: null,
      },
      () => {
        const { userData } = this.props;

        if (bio === userData.bio) {
          return;
        }

        this.setState(
          {
            performingAction: true,
          },
          () => {
            authentication
              .changeBio(bio)
              .catch((reason) => {
                if (!reason) return;
                const code = reason.code;
                const message = reason.message;

                switch (code) {
                  default:
                    this.props.openSnackbar(message);
                    return;
                }
              })
              .finally(() => {
                this.setState({
                  performingAction: false,
                });
              });
          }
        );
      }
    );
  };

  changeLocation = (location: string) => {
    const errors = validate(
      {
        location: location,
      },
      {
        location: constraints.location,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });

      return;
    }

    this.setState(
      {
        errors: null,
      },
      () => {
        const { userData } = this.props;

        if (location === userData.location) {
          return;
        }

        authentication.changeLocation(location).catch((reason) => {
          if (!reason) return;
          const code = reason.code;
          const message = reason.message;

          switch (code) {
            default:
              this.props.openSnackbar(message);
              return;
          }
        });
      }
    );
  };

  changeEmailAddress = (emailAddress: string) => {
    const errors = validate(
      {
        emailAddress: emailAddress,
      },
      {
        emailAddress: constraints.emailAddress,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });

      return;
    }

    this.setState(
      {
        errors: null,
      },
      () => {
        const { user } = this.props;

        if (emailAddress === user.email) {
          return;
        }

        authentication.changeEmailAddress(emailAddress).catch((reason) => {
          if (!reason) return;
          const code = reason.code;
          const message = reason.message;

          switch (code) {
            default:
              this.props.openSnackbar(message);
              return;
          }
        });
      }
    );
  };

  verifyEmailAddress = () => {
    this.setState(
      {
        performingAction: true,
      },
      () => {
        authentication
          .verifyEmailAddress()
          .then(() => {
            this.setState(
              {
                sentVerificationEmail: true,
              },
              () => {
                this.props.openSnackbar("Sent verification e-mail");
              }
            );
          })
          .catch((reason) => {
            if (!reason) return;
            const code = reason.code;
            const message = reason.message;

            switch (code) {
              default:
                this.props.openSnackbar(message);
                return;
            }
          })
          .finally(() => {
            this.setState({
              performingAction: false,
            });
          });
      }
    );
  };

  deleteAccount = () => {
    this.setState(
      {
        performingAction: true,
      },
      () => {
        authentication
          .deleteAccount()
          .then(() => {
            this.props.openSnackbar("Deleted account");
          })
          .catch((reason) => {
            if (!reason) return;
            const code = reason.code;
            const message = reason.message;

            switch (code) {
              default:
                this.props.openSnackbar(message);
                return;
            }
          })
          .finally(() => {
            this.setState({
              performingAction: false,
            });
          });
      }
    );
  };

  handleKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>,
    fieldId: string
  ) => {};

  handleAvatarChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event) {
      return;
    }

    const files = event.target.files;

    if (!files) {
      return;
    }

    const avatar = files[0];

    if (!avatar) {
      return;
    }

    const fileTypes = [
      "image/gif",
      "image/jpeg",
      "image/png",
      "image/webp",
      "image/svg+xml",
    ];

    if (!fileTypes.includes(avatar.type)) {
      return;
    }

    if (avatar.size > 20 * 1024 * 1024) {
      return;
    }

    this.setState(
      {
        avatar: avatar,
        avatarUrl: URL.createObjectURL(avatar),
      },
      () => {
        this.props.openSnackbar(`Selected image “${avatar.name}”`, 5);
      }
    );
  };

  handleFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event) {
      return;
    }

    const firstName = event.target.value;

    this.setState({
      firstName: firstName,
    });
  };

  handleLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event) {
      return;
    }

    const lastName = event.target.value;

    this.setState({
      lastName: lastName,
    });
  };

  handleAgeChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event) {
      return;
    }

    const age = event.target.value;

    this.setState({
      age: age,
    });
  };

  handleBioChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event) {
      return;
    }

    const bio = event.target.value;

    this.setState({
      bio: bio,
    });
  };

  handleLocationChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event) {
      return;
    }

    const location = event.target.value;

    this.setState({
      location: location,
    });
  };

  handleUsernameChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event) {
      return;
    }

    const username = event.target.value;

    this.setState({
      username: username,
    });
  };

  handleEmailAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event) {
      return;
    }

    const emailAddress = event.target.value;

    this.setState({
      emailAddress: emailAddress,
    });
  };

  render() {
    // Styling
    const { classes } = this.props;

    // Properties
    const { user, userData } = this.props;

    const { performingAction, errors } = this.state;

    const hasFirstName = userData && userData.firstName;
    const hasLastName = userData && userData.lastName;
    const hasUsername = userData && userData.username;
    const hasAge = userData && userData.age;
    const hasBio = userData && userData.bio;
    const hasLocation = userData && userData.location;

    return (
      <DialogContent classes={{ root: classes.dialogContent }}>
        <List disablePadding>
          <ListItem>
            <TextField
              className={classes.inputFocused}
              style={{ fontFamily: "EuropaBold" }}
              autoComplete="given-name"
              disabled={performingAction}
              error={!!(errors && errors.firstName)}
              fullWidth
              helperText={errors && errors.firstName ? errors.firstName[0] : ""}
              label="First name"
              type="text"
              defaultValue={hasFirstName && userData.firstName}
              onBlur={(e) => this.changeFirstName(e.target.value)}
              variant="outlined"
            />
          </ListItem>

          <ListItem>
            <TextField
              className={classes.inputFocused}
              autoComplete="family-name"
              disabled={performingAction}
              error={!!(errors && errors.lastName)}
              fullWidth
              helperText={errors && errors.lastName ? errors.lastName[0] : ""}
              label="Last name"
              type="text"
              defaultValue={hasLastName && userData.lastName}
              onBlur={(e) => this.changeLastName(e.target.value)}
              variant="outlined"
            />
          </ListItem>

          <ListItem>
            <TextField
              className={classes.inputFocused}
              autoComplete="username"
              disabled={performingAction}
              error={!!(errors && errors.username)}
              fullWidth
              helperText={errors && errors.username ? errors.username[0] : ""}
              label="Username"
              type="text"
              defaultValue={hasUsername && userData.username}
              onBlur={(e) => this.changeUsername(e.target.value)}
              variant="outlined"
            />
          </ListItem>

          <ListItem>
            <TextField
              className={classes.inputFocused}
              autoComplete="age"
              disabled={performingAction}
              error={!!(errors && errors.age)}
              fullWidth
              helperText={errors && errors.age ? errors.age[0] : ""}
              label="Age"
              type="text"
              defaultValue={hasAge && userData.age}
              onBlur={(e) => this.changeAge(e.target.value)}
              variant="outlined"
            />
          </ListItem>

          <ListItem>
            <TextField
              className={classes.inputFocused}
              autoComplete="location"
              disabled={performingAction}
              error={!!(errors && errors.location)}
              fullWidth
              helperText={errors && errors.location ? errors.location[0] : ""}
              label="Zip Code"
              type="text"
              defaultValue={hasLocation && userData.location}
              onBlur={(e) => this.changeLocation(e.target.value)}
              variant="outlined"
            />
          </ListItem>

          <ListItem>
            <TextField
              className={classes.inputFocused}
              autoComplete="bio"
              disabled={performingAction}
              error={!!(errors && errors.bio)}
              fullWidth
              helperText={errors && errors.bio ? errors.bio[0] : ""}
              label="Bio"
              type="text"
              defaultValue={hasBio && userData.bio}
              onBlur={(e) => this.changeBio(e.target.value)}
              variant="outlined"
            />
          </ListItem>

          <ListItem>
            <TextField
              className={classes.inputFocused}
              autoComplete="email"
              disabled={performingAction}
              error={!!(errors && errors.emailAddress)}
              fullWidth
              helperText={
                errors && errors.emailAddress ? errors.emailAddress[0] : ""
              }
              label="E-mail address"
              type="email"
              defaultValue={user.email}
              onBlur={(e) => this.changeEmailAddress(e.target.value)}
              variant="outlined"
            />
          </ListItem>

          <ListItem>
            <Button
              fullWidth
              color="secondary"
              size="large"
              variant="contained"
              onClick={() => authentication.signOut()}
            >
              Logout
            </Button>
          </ListItem>
        </List>
      </DialogContent>
    );
  }

  componentDidMount() {
    const { user, userData } = this.props;

    this.setState({
      profileCompletion: authentication.user.getProfileCompletion({
        ...user,
        ...userData,
      }),
      securityRating: authentication.user.getSecurityRating(user, userData),
    });
  }

  componentWillUnmount() {
    const { avatarUrl } = this.state;

    if (avatarUrl) {
      URL.revokeObjectURL(avatarUrl);

      this.setState({
        avatarUrl: "",
      });
    }
  }
}

export default withStyles(styles)(AccountTab);
