import { createContext } from "react";
import { CustomPlace } from "../../services/places";

const NativePlacesContext = createContext(
  {} as {
    nativePlaces: Array<CustomPlace>;
    setNativePlaces: (places: Array<CustomPlace>) => void;
  }
);

export const NativePlacesProvider = NativePlacesContext.Provider;
export const NativePlacesConsumer = NativePlacesContext.Consumer;

export default NativePlacesContext;
