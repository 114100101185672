import { default as React, useState } from "react";
import {
  Container,
  Box,
  Button,
  Theme,
  createStyles,
  makeStyles,
  TextField,
} from "@material-ui/core";
import NativeIcon from "../../icons/NativeIcon";
import authentication from "../../services/authentication";
import { useHistory } from "react-router-dom";

export type LoginScreenProps = {};

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    box1: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flex: "1",
    },
    box2: {
      display: "flex",
      flexDirection: "column",
      flex: "1",
    },
    svgRoot: {
      height: theme.spacing(15),
      width: theme.spacing(15),
    },
    text: {
      textAlign: "center",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    textField: {
      text: theme.palette.secondary.main,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    buttonPrimary: {
      // flex: "1",
      textTransform: "none",
      backgroundColor: "#f25f5c",
      borderRadius: theme.spacing(1),
      margin: theme.spacing(1),
      padding: theme.spacing(1),
    },
    buttonSecondary: {
      // flex: "1",
      textTransform: "none",
      backgroundColor: "#50514f",
      borderRadius: theme.spacing(1),
      margin: theme.spacing(1),
      padding: theme.spacing(1),
    },
  });

const useStyles = makeStyles(styles);

export const LoginScreen: React.FC<LoginScreenProps> = () => {
  const classes = useStyles();
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();

  return (
    <Container className={classes.container}>
      <Box className={classes.box1}>
        <NativeIcon color="secondary" classes={{ root: classes.svgRoot }} />
      </Box>
      <Box className={classes.box2}>
        <TextField
          id="email"
          label="Email"
          type="email"
          value={email}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value);
          }}
          variant="outlined"
          error={emailInvalid}
          className={classes.textField}
        />
        <TextField
          id="password"
          label="Password"
          type="password"
          value={password}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(event.target.value);
          }}
          variant="outlined"
          className={classes.textField}
        />
        <Button
          className={classes.buttonPrimary}
          variant="contained"
          color="secondary"
          onClick={async () => {
            if (email.length > 0 && password.length > 0) {
              authentication.signIn(email, password, history).catch((error) => {
                if (error) {
                  if (error.message) {
                    alert(error.message);
                  } else {
                    alert(error);
                  }
                }
              });
            }
          }}
        >
          Login
        </Button>
        <Button
          className={classes.buttonPrimary}
          variant="contained"
          color="secondary"
          onClick={() => {
            if (email.length > 0) {
              authentication.resetPassword(email).then(() => {
                alert("Reset Email Sent!");
              });
            } else {
              setEmailInvalid(true);
            }
          }}
        >
          Forgot Password?
        </Button>
      </Box>
    </Container>
  );
};

export default LoginScreen;
