import { default as React, useState, useEffect, useContext } from "react";
import categories from "../../services/categories";
import {
  Toolbar,
  Button,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";

import LocalCafe from "../../icons/LocalCafe";
import TerrainIcon from "../../icons/TerrainIcon";
import ShoppingIcon from "../../icons/ShoppingIcon";
import BarIcon from "../../icons/BarIcon";
import CameraIcon from "../../icons/CameraIcon";
import ServicesIcon from "../../icons/ServicesIcon";
import DiningIcon from "../../icons/DiningIcon";
import EventsIcon from "../../icons/EventsIcon";
import MoviesIcon from "../../icons/MoviesIcon";
import HappyIcon from "../../icons/HappyIcon";

import { grey } from "@material-ui/core/colors";
import PlacesContext from "../../contexts/PlacesContext";
import PlacesApi from "../../services/places";
import NativePlacesContext from "../../contexts/NativePlacesContext";
import CenterContext from "../../contexts/CenterContext";

export type CategoriesGroupProps = {};

const styles = (theme: Theme) =>
  createStyles({
    emptyStateIcon: {
      fontSize: theme.spacing(12),
    },

    suggestBox: {
      margin: 0,
      display: "flex",
      width: "100%",
    },

    suggestInput: {
      flex: "1",
    },

    button: {
      marginTop: theme.spacing(1),
    },

    buttonIcon: {
      marginRight: theme.spacing(1),
    },

    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
    },

    categoryToolbar: {
      // maxWidth: "350px",
      minHeight: "128px",
      display: "flex",
      flexFlow: "row wrap",
      paddingBottom: theme.spacing(1),
      // margin: "0 auto"
    },

    categoryButtonDiv: {
      flex: "0 1 calc(20% - 8px)",
      margin: "4px 2px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      fontSize: "x-small",
      textAlign: "center",
    },

    categoryButton: {
      backgroundColor: grey[200],
      padding: "3px",
      minWidth: "24px",
      border: "none",
      marginRight: "auto",
      marginLeft: "auto",
    },
  });

const useStyles = makeStyles(styles);

export const CategoriesGroup: React.FC<CategoriesGroupProps> = () => {
  const [catGroups, setCatGroups] = useState([] as Array<string>);
  const { setPlaces } = useContext(PlacesContext);
  const { setNativePlaces } = useContext(NativePlacesContext);
  const { center } = useContext(CenterContext);

  useEffect(() => {
    const getCatGroups = async () => {
      let groups = await categories.getCategoryGroupsForCurrentUser();
      if (!groups) groups = [];
      setCatGroups(groups);
    };
    getCatGroups();
    return () => {};
  }, []);

  const classes = useStyles();

  const onCategoryGroupClicked = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const name = event.currentTarget.id;
    let newGroups = catGroups.includes(name)
      ? catGroups.filter((group) => {
          return group !== name;
        })
      : [...catGroups, name];
    setCatGroups(newGroups);
    await categories.setCategoryGroupForUser(name, !catGroups.includes(name));
    let places = await PlacesApi.getFavoritesList();
    let nativePlaces = await PlacesApi.getNativePlacesList(
      center.lat,
      center.lng
    );
    setPlaces(places);
    setNativePlaces(nativePlaces);
  };

  return (
    <Toolbar className={classes.categoryToolbar} variant="regular">
      <div className={classes.categoryButtonDiv}>
        {/* these buttons need to be componetized so that we don't have to use multiple magic strings */}
        <Button
          size="medium"
          variant="outlined"
          className={classes.categoryButton}
          id="localCafe"
          onClick={onCategoryGroupClicked}
          color={catGroups.includes("localCafe") ? "secondary" : "default"}
        >
          <LocalCafe fontSize="large" />
        </Button>
      </div>
      <div className={classes.categoryButtonDiv}>
        <Button
          size="medium"
          variant="outlined"
          className={classes.categoryButton}
          id="outdoor"
          onClick={onCategoryGroupClicked}
          color={catGroups.includes("outdoor") ? "secondary" : "default"}
        >
          <TerrainIcon fontSize="large" />
        </Button>
      </div>
      <div className={classes.categoryButtonDiv}>
        <Button
          size="medium"
          variant="outlined"
          className={classes.categoryButton}
          id="shopping"
          onClick={onCategoryGroupClicked}
          color={catGroups.includes("shopping") ? "secondary" : "default"}
        >
          <ShoppingIcon fontSize="large" />
        </Button>
      </div>
      <div className={classes.categoryButtonDiv}>
        <Button
          size="medium"
          variant="outlined"
          className={classes.categoryButton}
          id="bars"
          onClick={onCategoryGroupClicked}
          color={catGroups.includes("bars") ? "secondary" : "default"}
        >
          <BarIcon fontSize="large" />
        </Button>
      </div>
      <div className={classes.categoryButtonDiv}>
        <Button
          size="medium"
          variant="outlined"
          className={classes.categoryButton}
          id="sightseeing"
          onClick={onCategoryGroupClicked}
          color={catGroups.includes("sightseeing") ? "secondary" : "default"}
        >
          <CameraIcon fontSize="large" />
        </Button>
      </div>
      <div className={classes.categoryButtonDiv}>
        <Button
          size="medium"
          variant="outlined"
          className={classes.categoryButton}
          id="services"
          onClick={onCategoryGroupClicked}
          color={catGroups.includes("services") ? "secondary" : "default"}
        >
          <ServicesIcon fontSize="large" />
        </Button>
      </div>
      <div className={classes.categoryButtonDiv}>
        <Button
          size="medium"
          variant="outlined"
          className={classes.categoryButton}
          id="dining"
          onClick={onCategoryGroupClicked}
          color={catGroups.includes("dining") ? "secondary" : "default"}
        >
          <DiningIcon fontSize="large" />
        </Button>
      </div>
      <div className={classes.categoryButtonDiv}>
        <Button
          size="medium"
          variant="outlined"
          className={classes.categoryButton}
          id="events"
          onClick={onCategoryGroupClicked}
          color={catGroups.includes("events") ? "secondary" : "default"}
        >
          <EventsIcon fontSize="large" />
        </Button>
      </div>
      <div className={classes.categoryButtonDiv}>
        <Button
          size="medium"
          variant="outlined"
          className={classes.categoryButton}
          id="movies"
          onClick={onCategoryGroupClicked}
          color={catGroups.includes("movies") ? "secondary" : "default"}
        >
          <MoviesIcon fontSize="large" />
        </Button>
      </div>
      <div className={classes.categoryButtonDiv}>
        <Button
          size="medium"
          variant="outlined"
          className={classes.categoryButton}
          id="personal"
          onClick={onCategoryGroupClicked}
          color={catGroups.includes("personal") ? "secondary" : "default"}
        >
          <HappyIcon fontSize="large" />
        </Button>
      </div>
    </Toolbar>
  );
};

export default CategoriesGroup;
