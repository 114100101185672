import { default as React, useEffect, useState } from "react";
import { firestore } from "../../firebase";
import { makeStyles, Typography } from "@material-ui/core";
import FriendIcon from "../FriendIcon";

interface FriendCardProps {
  userId: string;
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexWrap: "wrap",
    paddingLeft: "24px",
    paddingRight: "24px",
    justifyContent: "center",
  },
  unit: {
    maxWidth: "96px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "24px",
    paddingLeft: "24px",
    paddingRight: "24px",
  },
  text: {
    paddingTop: "4px",
    fontSize: "8px",
    textAlign: "center",
  },
  bottomText: {
    fontSize: "8px",
    textAlign: "center",
    lineHeight: "1",
  },
});

const FriendCard: React.FC<FriendCardProps> = ({ userId }) => {
  const classes = useStyles();
  const [user, setUser] = useState(
    {} as { username: string; bio: string; age: string }
  );

  useEffect(() => {
    const getURL = async () => {
      const userName = firestore.collection("users").doc(userId);
      const user = await userName.get();
      setUser(user.data() as { username: string; bio: string; age: string });
    };
    getURL();
    return () => {};
  }, [userId]);

  return (
    <div className={classes.unit} key={userId}>
      <FriendIcon userId={userId} />
      <Typography className={classes.text}>{"@" + user.username}</Typography>
      <Typography className={classes.bottomText}>
        {user.age + ", " + user.bio}
      </Typography>
    </div>
  );
};

export default FriendCard;
