import { default as React, useState } from "react";
import { UserProfile } from "../../services/users";
import { List, ListItem, TextField } from "@material-ui/core";
import UserRow from "../UserRow";

export type UserSearchSectionProps = {
  users: Array<UserProfile>;
  followers: Array<string>;
  following: Array<string>;
  section: "followers" | "following" | "all";
  refresh: Function;
  close: Function;
};

export const UserSearchSection: React.FC<UserSearchSectionProps> = ({
  users,
  followers,
  following,
  section,
  refresh,
  close
}) => {
  const [searchText, setSearchText] = useState("");

  return (
    <>
      <TextField
        fullWidth
        variant="filled"
        value={searchText}
        onChange={e => setSearchText(e.target.value)}
        color="secondary"
        label="Search"
        InputProps={{
          disableUnderline: true
        }}
      />
      <List>
        {users
          .filter(user => {
            switch (section) {
              case "followers":
                return followers.includes(user.id);
              case "following":
                return following.includes(user.id);
              case "all":
              default:
                return true;
            }
          })
          .filter(
            user =>
              (user.firstName && user.firstName.includes(searchText)) ||
              (user.lastName && user.lastName.includes(searchText)) ||
              (user.username && user.username.includes(searchText))
          )
          .map(user => {
            return (
              <ListItem key={user.id}>
                <UserRow
                  user={user}
                  following={following.includes(user.id)}
                  refresh={refresh}
                  close={close}
                />
              </ListItem>
            );
          })}
      </List>
    </>
  );
};

export default UserSearchSection;
