import { default as React } from "react";
import { useHistory } from "react-router-dom";
import { Container, Box, Theme, Typography, Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import NativeIcon from "../../icons/NativeIcon";
import authentication from "../../services/authentication";

export type SignUpPathScreenProps = {};

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    box1: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flex: "1",
    },
    box2: {
      display: "flex",
      flexDirection: "column",
      flex: "1",
    },
    svgRoot: {
      height: theme.spacing(15),
      width: theme.spacing(15),
    },
    text: {
      textAlign: "center",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    buttonPrimary: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      backgroundColor: "#f25f5c",
      borderRadius: theme.spacing(1),
    },
    buttonSecondary: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      backgroundColor: "#50514f",
      borderRadius: theme.spacing(1),
    },
  });

const useStyles = makeStyles(styles);

export const SignUpPathScreen: React.FC<SignUpPathScreenProps> = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Container className={classes.container}>
      <Box className={classes.box1}>
        <NativeIcon color="secondary" classes={{ root: classes.svgRoot }} />
      </Box>
      <Box className={classes.box2}>
        <Typography
          variant="h3"
          component="h1"
          className={classes.text}
          color="secondary"
          style={{ fontFamily: "EuropaBold" }}
        >
          Welcome to Native
        </Typography>
        <Typography
          style={{ fontFamily: "EuropaBold" }}
          variant="body2"
          component="h2"
          className={classes.text}
        >
          Continue with:
        </Typography>
        <Button
          style={{ fontFamily: "EuropaBold" }}
          className={classes.buttonPrimary}
          variant="contained"
          color="secondary"
          size="large"
          onClick={() => {
            history.push("/signup");
          }}
        >
          Email
        </Button>
        <Button
          style={{ fontFamily: "EuropaBold" }}
          className={classes.buttonSecondary}
          variant="contained"
          color="secondary"
          size="large"
          onClick={() => {
            authentication.signInWithAuthProvider("facebook.com", history);
          }}
        >
          Facebook
        </Button>
        <Button
          style={{ fontFamily: "EuropaBold" }}
          className={classes.buttonSecondary}
          variant="contained"
          color="secondary"
          size="large"
          onClick={() => {
            authentication.signInWithAuthProvider("google.com", history);
          }}
        >
          Google
        </Button>
      </Box>
    </Container>
  );
};

export default SignUpPathScreen;
