import React, { Component, ChangeEvent } from "react";

import { withStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import Hidden from "@material-ui/core/Hidden";

import CloseIcon from "@material-ui/icons/Close";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import GroupIcon from "@material-ui/icons/Group";
import PreferencesIcon from "../../icons/PreferencesIcon";
import PersonIcon from "@material-ui/icons/Person";
import {
  WithStyles,
  Theme,
  Box,
  Badge,
  Fab,
  Fade,
  CircularProgress,
  Avatar,
} from "@material-ui/core";

import AccountTab from "../AccountTab";
import FriendsTab from "../FriendsTab";
import CategoriesTab from "../CategoriesTab";
import authentication from "../../services/authentication";

const styles = (theme: Theme) => ({
  closeButton: {
    position: "absolute" as "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },

  badge: {
    top: theme.spacing(2),
    right: -theme.spacing(2),
  },

  loadingBadge: {
    top: "50%",
    right: "50%",
  },

  avatar: {
    marginRight: "auto",
    marginLeft: "auto",

    width: theme.spacing(14),
    height: theme.spacing(14),
  },

  nameInitials: {
    cursor: "default",
  },

  personIcon: {
    fontSize: theme.spacing(7),
  },

  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),

    minHeight: "initial",
  },
});

const tabs = [
  {
    key: "account",
    icon: <AccountCircleIcon />,
    label: "Update Info",
  },
  {
    key: "categories",
    icon: <PreferencesIcon />,
    label: "Preferences",
  },
  {
    key: "friends",
    icon: <GroupIcon />,
    label: "Friends",
  },
];

const initialState: SettingsDialogState = {
  selectedTab: 0,
  performingAction: false,
  loadingAvatar: false,
  avatar: null,
  avatarUrl: "",
};

interface SettingsDialogState {
  selectedTab: number;
  performingAction: boolean;
  loadingAvatar: boolean;
  avatar: null | File;
  avatarUrl: string;
}

interface SettingsDialogProps extends WithStyles<typeof styles> {
  user: {
    photoURL: string;
    email: string;
    emailVerified: boolean;
    metadata: {
      lastSignInTime: number;
    };
  };
  userData: {
    lastName: string;
    firstName: string;
    username: string;
    age: string;
    bio: string;
    location: string;
  };
  openSnackbar: Function;
  close: Function;
  open: boolean;
}

class SettingsDialog extends Component<
  SettingsDialogProps,
  SettingsDialogState
> {
  constructor(props: SettingsDialogProps) {
    super(props);
    this.state = initialState;
  }

  getNameInitialsOrIcon = () => {
    const { user } = this.props;

    if (!user) {
      return null;
    }

    const { classes, userData } = this.props;

    if (!userData) {
      return <PersonIcon className={classes.personIcon} />;
    }

    const nameInitials = authentication.user.getNameInitials({
      ...user,
      ...userData,
    });

    if (nameInitials) {
      return nameInitials;
    }

    return <PersonIcon className={classes.personIcon} />;
  };

  handleExited = () => {
    this.setState(initialState);
    this.props.close();
  };

  handleTabChange = (event: React.ChangeEvent<{}>, value: number) => {
    this.setState({
      selectedTab: value,
    });
  };

  handleIndexChange = (index: number) => {
    this.setState({
      selectedTab: index,
    });
  };

  handleAvatarChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event) {
      return;
    }

    const files = event.target.files;

    if (!files) {
      return;
    }

    const avatar = files[0];

    if (!avatar) {
      return;
    }

    const fileTypes = [
      "image/gif",
      "image/jpeg",
      "image/png",
      "image/webp",
      "image/svg+xml",
    ];

    if (!fileTypes.includes(avatar.type)) {
      return;
    }

    if (avatar.size > 20 * 1024 * 1024) {
      return;
    }

    this.setState(
      {
        avatar: avatar,
        avatarUrl: URL.createObjectURL(avatar),
      },
      () => {
        this.uploadAvatar();
        this.props.openSnackbar(`Selected image “${avatar.name}”`, 5);
      }
    );
  };

  uploadAvatar = () => {
    const { avatar } = this.state;

    if (!avatar) {
      return;
    }

    this.setState(
      {
        performingAction: true,
        loadingAvatar: true,
      },
      () => {
        authentication.changeAvatar(avatar).finally(() => {
          this.setState({
            performingAction: false,
            loadingAvatar: false,

            avatar: null,
            avatarUrl: "",
          });
        });
      }
    );
  };

  removeAvatar = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    const { user } = this.props;
    const { avatar, avatarUrl } = this.state;

    if (!user.photoURL && !avatar && !avatarUrl) {
      return;
    }

    if (
      (!user.photoURL && avatar && avatarUrl) ||
      (user.photoURL && avatar && avatarUrl)
    ) {
      URL.revokeObjectURL(avatarUrl);

      this.setState(
        {
          avatar: null,
          avatarUrl: "",
        },
        () => {
          this.props.openSnackbar(`Removed image “${avatar.name}”`, 5);
        }
      );
    } else if (user.photoURL && !avatar && !avatarUrl) {
      this.setState(
        {
          performingAction: true,
          loadingAvatar: true,
        },
        () => {
          authentication.removeAvatar().finally(() => {
            this.setState({
              performingAction: false,
              loadingAvatar: false,
            });
          });
        }
      );
    }
  };

  render() {
    // Styling
    const { classes } = this.props;

    // Custom Properties
    const { user, userData, open, close } = this.props;

    // Custom Functions
    const { openSnackbar } = this.props;

    const {
      selectedTab,
      loadingAvatar,
      avatar,
      avatarUrl,
      performingAction,
    } = this.state;

    return (
      <Dialog open={open} fullScreen onExited={this.handleExited}>
        <DialogTitle disableTypography>
          <Typography variant="h6">Settings</Typography>
          <Tooltip title="Close">
            <IconButton
              className={classes.closeButton}
              disabled={this.state.performingAction}
              onClick={close as any}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <input
          id="avatar-input"
          type="file"
          hidden
          accept="image/*"
          onChange={this.handleAvatarChange}
        />

        <label htmlFor="avatar-input">
          <Box textAlign="center" mb={1.5}>
            {avatar && avatarUrl && (
              <Badge
                classes={{ badge: classes.badge }}
                badgeContent={
                  <Tooltip title="Remove">
                    <Fab
                      classes={{ sizeSmall: classes.small }}
                      color="secondary"
                      disabled={performingAction}
                      size="small"
                      onClick={this.removeAvatar}
                    >
                      <CloseIcon fontSize="small" />
                    </Fab>
                  </Tooltip>
                }
              >
                {loadingAvatar && (
                  <Badge
                    classes={{ badge: classes.loadingBadge }}
                    badgeContent={
                      <Fade
                        style={{ transitionDelay: "1s" }}
                        in={loadingAvatar}
                        unmountOnExit
                      >
                        <CircularProgress size={120} thickness={1.8} />
                      </Fade>
                    }
                  >
                    <Avatar
                      className={classes.avatar}
                      alt="Avatar"
                      src={avatarUrl}
                    />
                  </Badge>
                )}

                {!loadingAvatar && (
                  <Avatar
                    className={classes.avatar}
                    alt="Avatar"
                    src={avatarUrl}
                  />
                )}
              </Badge>
            )}

            {!avatar && !avatarUrl && (
              <>
                {user.photoURL && (
                  <Badge
                    classes={{ badge: classes.badge }}
                    badgeContent={
                      <Tooltip title="Remove">
                        <Fab
                          classes={{ sizeSmall: classes.small }}
                          color="secondary"
                          disabled={performingAction}
                          size="small"
                          onClick={this.removeAvatar}
                        >
                          <CloseIcon fontSize="small" />
                        </Fab>
                      </Tooltip>
                    }
                  >
                    {loadingAvatar && (
                      <Badge
                        classes={{ badge: classes.loadingBadge }}
                        badgeContent={
                          <Fade
                            style={{ transitionDelay: "1s" }}
                            in={loadingAvatar}
                            unmountOnExit
                          >
                            <CircularProgress size={120} thickness={1.8} />
                          </Fade>
                        }
                      >
                        <Avatar
                          className={classes.avatar}
                          alt="Avatar"
                          src={user.photoURL}
                        />
                      </Badge>
                    )}

                    {!loadingAvatar && (
                      <Avatar
                        className={classes.avatar}
                        alt="Avatar"
                        src={user.photoURL}
                      />
                    )}
                  </Badge>
                )}

                {!user.photoURL && (
                  <>
                    {loadingAvatar && (
                      <Badge
                        classes={{ badge: classes.loadingBadge }}
                        badgeContent={
                          <Fade
                            style={{ transitionDelay: "1s" }}
                            in={loadingAvatar}
                            unmountOnExit
                          >
                            <CircularProgress size={120} thickness={1.8} />
                          </Fade>
                        }
                      >
                        <Avatar className={classes.avatar} alt="Avatar">
                          <Typography
                            className={classes.nameInitials}
                            variant="h2"
                          >
                            {this.getNameInitialsOrIcon()}
                          </Typography>
                        </Avatar>
                      </Badge>
                    )}

                    {!loadingAvatar && (
                      <Avatar className={classes.avatar} alt="Avatar">
                        <Typography
                          className={classes.nameInitials}
                          variant="h2"
                        >
                          {this.getNameInitialsOrIcon()}
                        </Typography>
                      </Avatar>
                    )}
                  </>
                )}
              </>
            )}
          </Box>
        </label>

        <Box flex={"center"} flexDirection={"column"}>
          <Hidden xsDown>
            <Tabs
              style={{
                overflow: "initial",
                minHeight: "initial",
                fontFamily: "EuropaBold",
              }}
              indicatorColor="secondary"
              value={selectedTab}
              centered
              variant="fullWidth"
              onChange={this.handleTabChange}
            >
              {tabs.map((tab) => {
                return (
                  <Tab
                    style={{ fontFamily: "EuropaBold" }}
                    key={tab.key}
                    icon={tab.icon}
                    label={tab.label}
                  />
                );
              })}
            </Tabs>
          </Hidden>

          <Hidden smUp>
            <Tabs
              style={{
                overflow: "initial",
                minHeight: "initial",
                fontFamily: "EuropaBold",
              }}
              centered
              indicatorColor="secondary"
              value={selectedTab}
              variant="fullWidth"
              onChange={this.handleTabChange}
            >
              {tabs.map((tab) => {
                return (
                  <Tab
                    style={{ fontFamily: "EuropaBold" }}
                    key={tab.key}
                    icon={tab.icon}
                    label={tab.label}
                  />
                );
              })}
            </Tabs>
          </Hidden>
          {selectedTab === 0 ? (
            <AccountTab
              user={user}
              userData={userData}
              openSnackbar={openSnackbar}
            />
          ) : null}
          {selectedTab === 1 ? <CategoriesTab /> : null}
          {selectedTab === 2 ? <FriendsTab close={close} /> : null}
        </Box>
      </Dialog>
    );
  }
}

export default withStyles(styles)(SettingsDialog);
