import { createContext } from "react";
import { CustomPlace } from "../../services/places";

const PlacesContext = createContext(
  {} as {
    places: Array<CustomPlace>;
    setPlaces: (places: Array<CustomPlace>) => void;
  }
);

export const PlacesProvider = PlacesContext.Provider;
export const PlacesConsumer = PlacesContext.Consumer;

export default PlacesContext;
