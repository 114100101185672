import { default as React, useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import { storage, firestore } from "../../firebase";

interface FriendIconProps {
  userId: string;
  variant?: "circle" | "rounded" | "square" | undefined;
}

const FriendIcon: React.FC<FriendIconProps> = ({ userId, variant }) => {
  const [iconSrc, setIconSrc] = useState(undefined as string | undefined);
  const [user, setUser] = useState({} as { username: string; email: string });
  useEffect(() => {
    const getURL = async () => {
      const imageReference = storage
        .ref()
        .child("images")
        .child("avatars")
        .child(userId);
      const userName = firestore.collection("users").doc(userId);

      const url = await imageReference
        .getDownloadURL()
        .catch((e) => console.log(e));
      const user = await userName.get();
      setIconSrc(url);
      setUser(user.data() as { username: string; email: string });
    };
    getURL();
    return () => {};
  }, [userId]);

  return (
    <div>
      <Avatar
        style={{ height: 68, width: 68 }}
        variant={variant}
        src={iconSrc}
        alt={user.username ? user.username : user.email}
        title={user.username ? user.username : user.email}
      />
    </div>
  );
};

export default FriendIcon;
