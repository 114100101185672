import { default as React, useState } from "react";
import {
  Theme,
  createStyles,
  makeStyles,
  Container,
  Box,
  Button,
  TextField,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import NativeIcon from "../../icons/NativeIcon";
import authentication from "../../services/authentication";

export type NewUserProps = {};

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    box1: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flex: "3",
    },
    box2: {
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
    },
    box3: {
      display: "flex",
      flexDirection: "column",
      flex: "1",
    },
    svgRoot: {
      height: theme.spacing(15),
      width: theme.spacing(15),
    },
    text: {
      textAlign: "center",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    buttonPrimary: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      backgroundColor: "#f25f5c",
      borderRadius: theme.spacing(1),
    },
    buttonSecondary: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      backgroundColor: "#50514f",
      borderRadius: theme.spacing(1),
    },
  });

const useStyles = makeStyles(styles);

export const NewUser: React.FC<NewUserProps> = () => {
  const classes = useStyles();
  const history = useHistory();
  const [age, setAge] = useState("");
  const [bio, setBio] = useState("");
  const [location, setLocation] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  return (
    <Container className={classes.container}>
      <Box className={classes.box1}>
        <NativeIcon color="secondary" classes={{ root: classes.svgRoot }} />
      </Box>
      <Box className={classes.box2}>
        <TextField
          id="firstName"
          label="First Name"
          type="name"
          value={firstName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFirstName(event.target.value);
          }}
          variant="outlined"
          className={classes.text}
        />
        <TextField
          id="lastName"
          label="Last Name"
          type="name"
          value={lastName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setLastName(event.target.value);
          }}
          variant="outlined"
          className={classes.text}
        />
        <TextField
          id="username"
          label="Username"
          type="Username"
          value={username}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setUsername(event.target.value);
          }}
          variant="outlined"
          className={classes.text}
        />
        <TextField
          id="age"
          label="Age"
          type="age"
          value={age}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setAge(event.target.value);
          }}
          variant="outlined"
          className={classes.text}
        />
        <TextField
          id="location"
          label="Zip Code"
          type="location"
          value={location}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setLocation(event.target.value);
          }}
          variant="outlined"
          className={classes.text}
        />
        <TextField
          id="bio"
          label="Bio"
          type="bio"
          value={bio}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setBio(event.target.value);
          }}
          variant="outlined"
          className={classes.text}
        />
      </Box>
      <Box className={classes.box3}>
        <Button
          style={{ fontFamily: "EuropaBold" }}
          className={classes.buttonPrimary}
          variant="contained"
          color="secondary"
          size="large"
          onClick={async () => {
            if (
              firstName.length > 0 &&
              lastName.length > 0 &&
              username.length > 0 &&
              age.length > 0 &&
              location.length > 0 &&
              bio.length > 0
            )
              await authentication.changeUsername(username);
            await authentication.changeAge(age);
            await authentication.changeFirstName(firstName);
            await authentication.changeLastName(lastName);
            await authentication.changeLocation(location);
            await authentication.changeBio(bio);
            history.push("/categories");
          }}
        >
          Continue
        </Button>
      </Box>
    </Container>
  );
};

export default NewUser;
