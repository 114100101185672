import { default as React } from "react";
import { makeStyles } from "@material-ui/styles";
import FriendCard from "../FriendCard";
import { auth } from "../../firebase";

export type FriendSectionProps = { friendIds: Array<string> };

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexWrap: "wrap",
    paddingLeft: "24px",
    paddingRight: "24px",
    justifyContent: "center",
  },
  unit: {
    maxWidth: "96px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "24px",
  },
  text: {
    paddingTop: "12px",
    fontSize: "8px",
    textAlign: "center",
  },
});

export const FriendSection: React.FC<FriendSectionProps> = ({ friendIds }) => {
  const classes = useStyles();

  let friends = friendIds.map((id) => {
    if (auth.currentUser?.uid === id) return null;
    return <FriendCard userId={id} />;
  });

  return <div className={classes.container}>{friends}</div>;
};

export default FriendSection;
