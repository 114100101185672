import firebase, { auth, firestore } from "../firebase";

export interface UserProfile {
  firstName: string;
  lastName: string;
  username: string;
  id: string;
  location: string;
  pending: boolean;
}

const users = {
  getUsers: () => {
    return new Promise((resolve, reject) => {
      const currentUser = auth.currentUser;

      if (!currentUser) {
        reject();

        return;
      }

      const uid = currentUser.uid;

      if (!uid) {
        reject();

        return;
      }

      const reference = firestore.collection("users");

      if (!reference) {
        reject();

        return;
      }

      reference
        .get()
        .then(async (snapshot) => {
          const users = [] as Array<UserProfile>;
          const data = snapshot.docs;
          for await (const doc of data) {
            const userData = {
              ...doc.data(),
              id: doc.id,
            };

            users.push(userData as UserProfile);
          }
          resolve(users);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    });
  },

  getFollowing: () => {
    return new Promise((resolve, reject) => {
      const currentUser = auth.currentUser;

      if (!currentUser) {
        reject();

        return;
      }

      const uid = currentUser.uid;

      if (!uid) {
        reject();

        return;
      }

      const reference = firestore.collection("users").doc(uid);

      if (!reference) {
        reject();

        return;
      }

      reference
        .get()
        .then(async (snapshot) => {
          const users = [] as Array<string>;
          const data = snapshot.data();
          if (!data) {
            reject();

            return;
          }
          if (Array.isArray(data.following) && data.following.length > 0) {
            data.following.forEach((id: string) => users.push(id));
          }
          resolve(users);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    });
  },

  getFollowers: () => {
    return new Promise((resolve, reject) => {
      const currentUser = auth.currentUser;

      if (!currentUser) {
        reject();

        return;
      }

      const uid = currentUser.uid;

      if (!uid) {
        reject();

        return;
      }

      const reference = firestore.collection("users").doc(uid);

      if (!reference) {
        reject();

        return;
      }

      reference
        .get()
        .then(async (snapshot) => {
          const users = [] as Array<string>;
          const data = snapshot.data();
          if (!data) {
            reject();

            return;
          }
          if (Array.isArray(data.followers) && data.followers.length > 0) {
            data.followers.forEach((id: string) => users.push(id));
          }
          resolve(users);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    });
  },

  setFollowerForUser: (id: string, checked: boolean) => {
    return new Promise<null>((resolve, reject) => {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        reject();
        return;
      }
      const uid = currentUser.uid;
      if (!uid) {
        reject();
        return;
      }
      let myRef = firestore.collection("users").doc(uid);
      let otherRef = firestore.collection("users").doc(id);

      if (checked) {
        myRef.set(
          {
            following: firebase.firestore.FieldValue.arrayUnion(id),
          },
          { merge: true }
        );
        otherRef.set(
          {
            followers: firebase.firestore.FieldValue.arrayUnion(uid),
          },
          { merge: true }
        );
        resolve();
      } else {
        myRef.set(
          {
            following: firebase.firestore.FieldValue.arrayRemove(id),
          },
          { merge: true }
        );
        otherRef.set(
          {
            followers: firebase.firestore.FieldValue.arrayRemove(uid),
          },
          { merge: true }
        );
        resolve();
      }
    });
  },
};

export default users;
