import React, { useContext, useRef, useState, useEffect } from "react";

import { GoogleMap, Marker } from "@react-google-maps/api";
import { Suggest } from "react-geosuggest";

import {
  withStyles,
  Theme,
  WithStyles,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";

import PlacesContext from "../../contexts/PlacesContext";
import CenterContext from "../../contexts/CenterContext";
import SelectedPlaceContext from "../../contexts/SelectedPlaceContext";
import PlacesApi, { CustomPlace } from "../../services/places";

import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import { auth } from "../../firebase";
import PlaceCard from "../PlaceCard";
import NativePlacesContext from "../../contexts/NativePlacesContext";
import SelectedUserContext from "../../contexts/SelectedUserContext";
import Welcome from "../Welcome";

interface MarkerI {
  description: string;
  label: string;
  placeId: string;
  location: { lat: number; lng: number };
  icon: string | undefined;
  friends: Array<string>;
}

const styles = (theme: Theme) =>
  createStyles({
    bottomButton: {
      fontSize: "0.875rem",
      color: theme.palette.secondary.main,
      borderTop: `2px solid ${theme.palette.secondary.main}`,
    },
    bottomButtonLabel: {
      fontSize: "0.875rem",
      borderTop: "unset",
      color: theme.palette.grey[500],
    },
    icon: {
      verticalAlign: "bottom",
      height: 20,
      width: 20,
    },
  });

const useStyles = makeStyles(styles);

const options = {
  streetViewControl: false,
  zoomControl: false,
  mapTypeControl: false,
  fullscreenControl: false,

  styles: [
    { elementType: "geometry", stylers: [{ color: "#ebe3cd" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#523735" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#f5f1e6" }] },
    {
      featureType: "administrative",
      elementType: "geometry.stroke",
      stylers: [{ color: "#c9b2a6" }],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "geometry.stroke",
      stylers: [{ color: "#dcd2be" }],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [{ color: "#ae9e90" }],
    },
    {
      featureType: "landscape.natural",
      elementType: "geometry",
      stylers: [{ color: "#dfd2ae" }],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [{ color: "#dfd2ae" }],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "#93817c" }],
    },
    {
      featureType: "poi.park",
      elementType: "geometry.fill",
      stylers: [{ color: "#a5b076" }],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{ color: "#447530" }],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#f5f1e6" }],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry",
      stylers: [{ color: "#fdfcf8" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#f8c967" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [{ color: "#e9bc62" }],
    },
    {
      featureType: "road.highway.controlled_access",
      elementType: "geometry",
      stylers: [{ color: "#e98d58" }],
    },
    {
      featureType: "road.highway.controlled_access",
      elementType: "geometry.stroke",
      stylers: [{ color: "#db8555" }],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [{ color: "#806b63" }],
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [{ color: "#dfd2ae" }],
    },
    {
      featureType: "transit.line",
      elementType: "labels.text.fill",
      stylers: [{ color: "#8f7d77" }],
    },
    {
      featureType: "transit.line",
      elementType: "labels.text.stroke",
      stylers: [{ color: "#ebe3cd" }],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [{ color: "#dfd2ae" }],
    },
    {
      featureType: "water",
      elementType: "geometry.fill",
      stylers: [{ color: "#b9d3c2" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ color: "#92998d" }],
    },
  ] as Array<google.maps.MapTypeStyle>,
} as google.maps.MapOptions;

const mapContainerStyle = {
  flex: "1",
};

interface SuggestFull extends Suggest {
  gmaps?: GeocoderResultFull;
}

interface GeocoderResultFull extends google.maps.GeocoderResult {
  name: string;
  opening_hours: google.maps.places.OpeningHours;
  price_level: number;
  rating: number;
}

interface HomeContentProps extends WithStyles<typeof styles> {
  user: firebase.User;
}

const HomeContent: React.FC<HomeContentProps> = ({ user, ...props }) => {
  const classes = useStyles(props);
  const { places, setPlaces } = useContext(PlacesContext);
  const { nativePlaces } = useContext(NativePlacesContext);
  const { center } = useContext(CenterContext);
  const { selectedPlace, setSelectedPlace } = useContext(SelectedPlaceContext);
  const { selectedUserId, setSelectedUserId } = useContext(SelectedUserContext);
  const [favoritesGroup, setfavoritesGroup] = useState(
    "" as "native" & "friends" & "favorites"
  );
  const [filteredMarkers, setFilteredMarkers] = useState([] as CustomPlace[]);
  const ref = useRef(null as null | GoogleMap);
  useEffect(() => {
    if (selectedUserId && selectedUserId !== "") {
      setFilteredMarkers(
        places.filter((place) => {
          return place.friends && place.friends.includes(selectedUserId);
        })
      );
    } else if (favoritesGroup === "native") {
      setFilteredMarkers(nativePlaces);
    } else {
      let placesToSet = places.filter((place) => {
        if (!auth.currentUser || !auth.currentUser.uid) return false;
        const uid = auth.currentUser.uid;
        const nativeVersion = nativePlaces.find((np) => {
          return np.id === place.id;
        });

        if (place.friends && nativeVersion && nativeVersion.friends) {
          let friends = place.friends
            ? [...place.friends, ...nativeVersion.friends]
            : nativeVersion.friends;
          let set = new Set(friends);
          place.friends = Array.from(set);
        }
        let set = new Set(place.friends);
        place.friends = Array.from(set);
        if (favoritesGroup === "friends") {
          place.icon = "/images/friends-location-pin.svg";
          return !place.friends?.includes(uid) || place.friends?.length > 1;
        } else if (favoritesGroup === "favorites") {
          place.icon = "/images/favorite-location-pin.svg";
          return place.friends?.includes(uid);
        } else {
          if (!place.friends || place.friends.includes(uid)) {
            place.icon = "/images/favorite-location-pin.svg";
          } else {
            place.icon = "/images/friends-location-pin.svg";
          }
          return true;
        }
      });
      if (favoritesGroup !== "friends" && favoritesGroup !== "favorites") {
        nativePlaces.forEach((nativePlace) => {
          const included = placesToSet.find((place) => {
            return place.id === nativePlace.id;
          });

          if (included) {
            placesToSet = placesToSet.filter((p) => p.id !== included.id);
            if (included.friends && nativePlace.friends) {
              let friends = included.friends
                ? [...included.friends, ...nativePlace.friends]
                : nativePlace.friends;
              let set = new Set(friends);
              included.friends = Array.from(set);
            }

            placesToSet.push(included);
          } else {
            placesToSet.push(nativePlace);
          }
        });
      }
      setFilteredMarkers(placesToSet);
    }
  }, [favoritesGroup, nativePlaces, places, selectedUserId]);

  const handleMarkerClick = (e: CustomPlace) => {
    setSelectedPlace(e);
  };

  const handleMapClick = async (e: any) => {
    e.stop();
    if (e.placeId && ref.current !== null) {
      let place = (await PlacesApi.getPlaceFromId(e.placeId)) as any;
      if (place) {
        setSelectedPlace(place);
      } else {
        setSelectedPlace(false);
      }
    } else {
      setSelectedPlace(false);
    }
  };

  const toggleSelectedToFavorites = async () => {
    if (selectedPlace) {
      selectedPlace.icon = "/images/favorite-location-pin.svg";
      const place = places.find((place) => {
        return (
          place.id === selectedPlace.id &&
          place.friends &&
          auth.currentUser &&
          place.friends.includes(auth.currentUser?.uid)
        );
      });
      if (place) {
        await PlacesApi.removeFavoriteForUser(selectedPlace.id);
        let newPlaces = await PlacesApi.getFavoritesList();
        let tempPlace = selectedPlace;
        tempPlace.icon = "/images/temp-location-marker.svg";
        setPlaces(newPlaces);
        setSelectedPlace(tempPlace);
      } else {
        await PlacesApi.setFavoriteForUser(selectedPlace);
        let newPlaces = await PlacesApi.getFavoritesList();
        setPlaces(newPlaces);
      }
    }
  };

  if (user) {
    return (
      <>
        <GoogleMap
          options={options}
          mapContainerStyle={mapContainerStyle}
          zoom={13}
          mapTypeId="Styled Map"
          center={center}
          ref={ref}
          onClick={handleMapClick}
        >
          <>
            {filteredMarkers.map((marker) => {
              return (
                <Marker
                  key={marker.id}
                  title={marker.name}
                  onClick={() => handleMarkerClick(marker)}
                  position={marker.position}
                  icon={marker.icon}
                />
              );
            })}
            {!filteredMarkers.find((mark) => {
              if (!selectedPlace) {
                return false;
              } else {
                return mark.id === selectedPlace.id;
              }
            }) ? (
              <Marker
                key={(selectedPlace as CustomPlace).id}
                title={(selectedPlace as CustomPlace).name}
                onClick={() => handleMarkerClick(selectedPlace as CustomPlace)}
                position={(selectedPlace as CustomPlace).position}
                icon={(selectedPlace as CustomPlace).icon}
              />
            ) : null}
          </>
        </GoogleMap>
        {/* this really needs to be a component */}
        {selectedPlace ? (
          <PlaceCard
            place={selectedPlace}
            setFavorite={toggleSelectedToFavorites}
            isFavorite={
              !!places.find(
                (place) =>
                  place.id === selectedPlace.id &&
                  place.friends &&
                  auth.currentUser &&
                  place.friends.includes(auth.currentUser?.uid)
              )
            }
          />
        ) : null}
        {!selectedPlace ? (
          <BottomNavigation
            value={selectedUserId ? "" : favoritesGroup}
            onChange={(event, newValue) => {
              setSelectedUserId("");
              setfavoritesGroup(newValue === favoritesGroup ? "" : newValue);
            }}
            showLabels
          >
            <BottomNavigationAction
              classes={{
                selected: classes.bottomButton,
                label: classes.bottomButtonLabel,
              }}
              showLabel
              value="native"
              label="Natives"
            />
            <BottomNavigationAction
              classes={{
                selected: classes.bottomButton,
                label: classes.bottomButtonLabel,
              }}
              showLabel
              value="friends"
              label="Friends"
            />
            <BottomNavigationAction
              showLabel
              classes={{
                selected: classes.bottomButton,
                label: classes.bottomButtonLabel,
              }}
              color="default"
              value="favorites"
              label="Favorites"
            />
          </BottomNavigation>
        ) : null}
      </>
    );
  }

  return <Welcome />;
};

export default withStyles(styles)(HomeContent);
