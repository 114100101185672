import { default as firebase, auth, firestore } from "../firebase";

export interface Category {
  displayName: string;
  name: string;
  id: string;
  selected: boolean;
}

const CategoriesApi = {
  getCategoryGroupsForCurrentUser: () => {
    return new Promise<Array<string>>((resolve, reject) => {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        reject();

        return;
      }
      const uid = currentUser.uid;
      if (!uid) {
        reject();

        return;
      }
      let reference = firestore.collection("users").doc(uid);
      reference.get().then(snapshot => {
        let data = snapshot.data();
        if (data) {
          const groups = data.categoryGroups as Array<string>;
          resolve(groups);
        } else {
          reject();
        }
      });
    });
  },

  setCategoryGroupForUser: (name: string, checked: boolean) => {
    return new Promise<null>((resolve, reject) => {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        reject();
        return;
      }
      const uid = currentUser.uid;
      if (!uid) {
        reject();
        return;
      }
      let reference = firestore.collection("users").doc(uid);
      if (checked) {
        reference.set(
          {
            categoryGroups: firebase.firestore.FieldValue.arrayUnion(name)
          },
          { merge: true }
        );
        resolve();
      } else {
        reference.set(
          {
            categoryGroups: firebase.firestore.FieldValue.arrayRemove(name)
          },
          { merge: true }
        );
        resolve();
      }
    });
  },

  setCategoryForUser: (catID: string, checked: boolean) => {
    return new Promise<null>((resolve, reject) => {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        reject();

        return;
      }
      const uid = currentUser.uid;
      if (!uid) {
        reject();

        return;
      }
      let reference = firestore
        .collection("users")
        .doc(uid)
        .collection("categories");
      if (checked) {
        reference.doc(catID).set({
          category: firestore.collection("categories").doc(catID)
        });
      } else {
        reference.doc(catID).delete();
        resolve();
      }
    });
  },

  getCategoriesList: () => {
    return new Promise<Category[]>((resolve, reject) => {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        reject();

        return;
      }
      const uid = currentUser.uid;
      if (!uid) {
        reject();

        return;
      }
      firestore
        .collection("categories")
        .get()
        .then(categoriesSnapshot => {
          let categories = categoriesSnapshot.docs.map(doc => {
            return {
              ...doc.data(),
              id: doc.id
            } as Category;
          });
          return categories;
        })
        .then(async categories => {
          return await firestore
            .collection("users")
            .doc(uid)
            .collection("categories")
            .get()
            .then(catSnapshot => {
              const userCatIDs = catSnapshot.docs.map(doc => doc.id);
              return categories.map(cat => {
                return {
                  ...cat,
                  selected: userCatIDs.includes(cat.id)
                };
              });
            });
        })
        .then(categories => {
          resolve(categories);
        })
        .catch(e => {
          reject(e);
        });
    });
  }
};

export default CategoriesApi;
