import { default as React } from "react";
import { SvgIconProps, SvgIcon } from "@material-ui/core";

export default (props: SvgIconProps) => {
  return (
    <SvgIcon width="47" height="9" viewBox="0 0 47 9" {...props}>
      <circle
        id="Ellipse_11"
        data-name="Ellipse 11"
        cx="4.5"
        cy="4.5"
        r="4.5"
        opacity="0.2"
      />
      <circle
        id="Ellipse_12"
        data-name="Ellipse 12"
        cx="4.5"
        cy="4.5"
        r="4.5"
        transform="translate(19)"
        opacity="0.198"
      />
      <path
        id="Path_66"
        data-name="Path 66"
        d="M4.273,0A4.273,4.273,0,1,1,0,4.273,4.273,4.273,0,0,1,4.273,0Z"
        transform="translate(38.459)"
        fill="#fff"
      />
    </SvgIcon>
  );
};
