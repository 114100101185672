import { default as React, useState } from "react";
import {
  Theme,
  createStyles,
  makeStyles,
  Container,
  Box,
  Typography,
  Button,
  Divider,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import GroupIcon from "../../icons/GroupIcon";
import PublicIcon from "@material-ui/icons/Public";
import OnboardingPlusIcon from "../../icons/OnboardingPlusIcon";
import PagerDotOne from "../../icons/PagerDotsIcons/PagerDotOne";
import PagerDotThree from "../../icons/PagerDotsIcons/PagerDotThree";
import PagerDotTwo from "../../icons/PagerDotsIcons/PagerDotTwo";

export type GettingStartedProps = {};

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      backgroundColor: theme.palette.secondary.main,
    },
    box1: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      flex: "1",
      paddingLeft: "10%",
      paddingRight: "10%",
    },
    box2: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      flex: "1",
    },
    box3: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      flex: "1",
    },
    button: {
      borderRadius: "20px",
      width: "50%",
      backgroundColor: "white",
    },
  });

const useStyles = makeStyles(styles);

export const GettingStarted: React.FC<GettingStartedProps> = () => {
  let classes = useStyles();
  let history = useHistory();
  let [position, setPosition] = useState(0);

  const nextState = () => {
    if (position === 2) {
      history.push("/");
    } else {
      setPosition(position + 1);
    }
  };

  return (
    <Container className={classes.container}>
      <Box textAlign="right" paddingTop="10px">
        <Button onClick={() => history.push("/")}>Skip</Button>
      </Box>
      <Box textAlign="center" className={classes.box1}>
        {position === 0 ? (
          <>
            <Typography
              style={{ fontFamily: "EuropaBold" }}
              variant="h4"
              component="h1"
              color="primary"
            >
              Find Places People Like You Enjoy
            </Typography>
            <Divider
              style={{
                width: "24px",
                marginTop: "12px",
                marginBottom: "12px",
                paddingLeft: "12px",
                paddingRight: "12px",
                backgroundColor: "white",
              }}
            />
            <Typography variant="body1" component="h2" color="primary">
              And Share Your World With Others
            </Typography>
          </>
        ) : null}
        {position === 1 ? (
          <>
            <Typography
              style={{ fontFamily: "EuropaBold" }}
              variant="h4"
              component="h1"
              color="primary"
            >
              Pick Your Favorite Places
            </Typography>
            <Divider
              style={{
                width: "24px",
                marginTop: "12px",
                marginBottom: "12px",
                paddingLeft: "12px",
                paddingRight: "12px",
                backgroundColor: "white",
              }}
            />
            <Typography variant="body1" component="h2" color="primary">
              To Build Your Native Map
            </Typography>
          </>
        ) : null}
        {position === 2 ? (
          <>
            <Typography
              style={{ fontFamily: "EuropaBold" }}
              variant="h4"
              component="h1"
              color="primary"
            >
              Add Your Friends
            </Typography>
            <Divider
              style={{
                width: "24px",
                marginTop: "12px",
                marginBottom: "12px",
                paddingLeft: "12px",
                paddingRight: "12px",
                backgroundColor: "white",
              }}
            />
            <Typography variant="body1" component="h2" color="primary">
              To See Their Favorite Places On Your Map
            </Typography>
          </>
        ) : null}
      </Box>
      <Box className={classes.box2}>
        {position === 0 ? (
          <PublicIcon style={{ fontSize: 178.14 }} color="primary" />
        ) : null}
        {position === 1 ? (
          <OnboardingPlusIcon style={{ fontSize: 240 }} color="primary" />
        ) : null}
        {position === 2 ? (
          <GroupIcon style={{ fontSize: 178.14 }} color="primary" />
        ) : null}
      </Box>
      <Box className={classes.box3}>
        {position === 0 ? <PagerDotOne style={{ fontSize: 60 }} /> : null}
        {position === 1 ? <PagerDotTwo style={{ fontSize: 60 }} /> : null}
        {position === 2 ? <PagerDotThree style={{ fontSize: 60 }} /> : null}
        <Button
          variant="contained"
          color="primary"
          size="large"
          style={{ fontFamily: "EuropaBold", color: "#1B1C20" }}
          className={classes.button}
          onClick={() => nextState()}
        >
          Next
        </Button>
      </Box>
    </Container>
  );
};

export default GettingStarted;
