import { default as React } from "react";
import { SvgIconProps, SvgIcon } from "@material-ui/core";

export default (props: SvgIconProps) => {
  return (
    <SvgIcon
      id="prefix__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 437.89 385.15"
      {...props}
    >
      <defs>
        <style>
          {
            ".prefix__cls-1{fill:#ff2f2f;stroke:#ff4b45;stroke-linecap:round;stroke-miterlimit:10;stroke-width:16px}"
          }
        </style>
      </defs>
      <title>{"native logo (web)"}</title>
      <path
        className="prefix__cls-1"
        d="M8 113.47v263.68M113.47 8L8 113.47M113.47 8v316.42M113.47 324.42L8 377.15M218.95 113.47v263.68M113.47 324.42l105.47 52.73M113.47 8l105.47 105.47M324.42 8v316.42M429.89 113.47v263.68M218.94 113.47L324.42 8M324.42 8l105.47 105.47M324.42 324.42l105.47 52.73M324.42 324.42l-105.48 52.73"
      />
    </SvgIcon>
  );
};
