import React from "react";

import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import HomeContent from "../HomeContent";
import NotFoundContent from "../NotFoundContent";
import LoginScreen from "../LoginScreen";
import SignupScreen from "../SignupScreen";
import MainAppBar from "../MainAppBar";
import { GettingStarted } from "../GettingStarted";
import SignUpPathScreen from "../SignUpPathScreen";
import CategoriesScreen from "../CategoriesScreen";
import NewUser from "../NewUser";

export interface RouterProps {
  user: any;
  userData: any;
  performingAction: boolean;
  openSnackbar: Function;
}

const Router: React.FC<RouterProps> = ({
  user,
  userData,
  performingAction,
  openSnackbar,
}) => {
  return (
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
      <Switch>
        <Route path="/getting-started" exact>
          <GettingStarted />
        </Route>
        <Route path="/categories" exact>
          {user ? <CategoriesScreen /> : <Redirect to="/" />}
        </Route>
        <Route path="/newuser" exact>
          {user ? <NewUser /> : "Loading"}
        </Route>
        <Route path="">
          {user ? (
            <MainAppBar
              performingAction={performingAction}
              user={user}
              userData={userData}
              openSnackbar={openSnackbar}
            />
          ) : null}
          <Switch>
            <Route path="/" exact>
              <HomeContent user={user} />
            </Route>
            <Route path="/login" exact>
              {user ? <Redirect to="/" /> : <LoginScreen />}
            </Route>
            <Route path="/signup" exact>
              {user ? <Redirect to="/" /> : <SignupScreen />}
            </Route>
            <Route path="/signuppath" exact>
              {user ? <Redirect to="/" /> : <SignUpPathScreen />}
            </Route>

            <Route>
              <NotFoundContent />
            </Route>
          </Switch>
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
