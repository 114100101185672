import { createContext } from "react";
import { CustomPlace } from "../../services/places";

const SelectedPlaceContext = createContext(
  {} as {
    selectedPlace: CustomPlace | false;
    setSelectedPlace: (selectedPlace: CustomPlace | false) => void;
  }
);

export const SelectedPlaceProvider = SelectedPlaceContext.Provider;
export const SelectedPlaceConsumer = SelectedPlaceContext.Consumer;

export default SelectedPlaceContext;
