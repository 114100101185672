import React, { useState, useEffect } from "react";

import { MuiThemeProvider } from "@material-ui/core/styles";

import CssBaseline from "@material-ui/core/CssBaseline";

import { auth, firestore } from "../../firebase";
import theming from "../../services/theming";
import PlacesApi, { CustomPlace } from "../../services/places";

import LaunchScreen from "../LaunchScreen";

import Router from "../Router";
import { Helmet } from "react-helmet";
import { PlacesProvider } from "../../contexts/PlacesContext";
import { CenterProvider } from "../../contexts/CenterContext";
import { SelectedPlaceProvider } from "../../contexts/SelectedPlaceContext";
import { SelectedUserProvider } from "../../contexts/SelectedUserContext";
import { User } from "firebase";
import { Snackbar } from "@material-ui/core";
import { NativePlacesProvider } from "../../contexts/NativePlacesContext";

interface DialogStates {
  [key: string]: boolean;
}

const App: React.FC = () => {
  const [theme] = useState(theming.defaultTheme);
  const [center, setCenter] = useState({ lat: 53.55, lng: 9.99 });
  const [selectedPlace, setSelectedPlace] = useState(
    false as false | CustomPlace
  );
  const [selectedUserId, setSelectedUserId] = useState("" as false | string);
  const [places, setPlaces] = useState([] as Array<CustomPlace>);
  const [nativePlaces, setNativePlaces] = useState([] as Array<CustomPlace>);
  const [ready, setReady] = useState(false);
  const [user, setUser] = useState(null as null | User);
  const [userData, setUserData] = useState({} as any);
  const [performingAction] = useState(false);
  const [snackbar, setSnackbar] = useState({
    autoHideDuration: 0,
    message: "",
    open: false,
  });

  const setSelectedPlaceAndCenter = (place: CustomPlace | false) => {
    setSelectedPlace(place);
    if (place) {
      setCenter(place.position);
    }
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        let newCenter = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        setCenter(newCenter);
      });
    }
    let removeReferenceListener: any;
    const removeAuthStateChangedObserver = auth.onAuthStateChanged(
      (newUser) => {
        if (!newUser) {
          setUser(null);
          setUserData(null);
          setReady(true);
          return;
        }
        const uid = newUser.uid;
        if (!uid) {
          //likely need to go through setup
          setUser(newUser);
          setUserData(null);
          setReady(true);
          return;
        }
        const reference = firestore.collection("users").doc(uid);
        if (!reference) {
          if (removeReferenceListener) {
            removeReferenceListener();
          }
          //likely need to go through setup
          setUser(newUser);
          setUserData(null);
          setReady(true);
          return;
        }
        removeReferenceListener = reference.onSnapshot(
          (snapshot) => {
            if (!snapshot.exists) {
              //likely need to go through setup
              setUser(newUser);
              setUserData(null);
              setReady(true);
              return;
            }
            const data = snapshot.data();
            if (!data) {
              if (removeReferenceListener) {
                removeReferenceListener();
              }

              //likely need to go through setup
              setUser(newUser);
              setUserData(null);
              setReady(true);
              return;
            }
            setUser(newUser);
            setUserData(data);
            setReady(true);
            return;
          },
          (error) => {
            setUser(newUser);
            setUserData(null);
            setReady(true);
          }
        );
      }
    );
    return () => {
      if (
        removeReferenceListener &&
        typeof removeReferenceListener === "function"
      ) {
        removeReferenceListener();
      }
      if (
        removeAuthStateChangedObserver &&
        typeof removeAuthStateChangedObserver === "function"
      ) {
        removeAuthStateChangedObserver();
      }
    };
  }, []);

  useEffect(() => {
    const getData = async () => {
      let favs = await PlacesApi.getFavoritesList();
      let nativeFavs = await PlacesApi.getNativePlacesList(
        center.lat,
        center.lng
      );
      setPlaces(favs as Array<CustomPlace>);
      setNativePlaces(nativeFavs as Array<CustomPlace>);
    };
    getData();
    return () => {
      // cleanup;
    };
  }, [ready, user, center]);

  useEffect(() => {
    const getData = async () => {
      let nativeFavs = await PlacesApi.getNativePlacesList(
        center.lat,
        center.lng
      );
      setNativePlaces(nativeFavs as Array<CustomPlace>);
    };
    getData();
    return () => {
      // cleanup;
    };
  }, [ready, user, center]);

  const closeSnackbar = () => {
    setSnackbar((state) => {
      return { ...state, open: false };
    });
  };

  const openSnackbar = (message: string, autoHideDuration = 2) => {
    setSnackbar({ open: true, autoHideDuration, message });
  };

  return (
    <MuiThemeProvider theme={theme}>
      <PlacesProvider
        value={{
          places,
          setPlaces,
        }}
      >
        <NativePlacesProvider
          value={{
            nativePlaces,
            setNativePlaces,
          }}
        >
          <CenterProvider
            value={{
              center: center,
              setCenter: setCenter,
            }}
          >
            <SelectedPlaceProvider
              value={{
                selectedPlace,
                setSelectedPlace: setSelectedPlaceAndCenter,
              }}
            >
              <SelectedUserProvider
                value={{
                  selectedUserId,
                  setSelectedUserId,
                }}
              >
                <Helmet>
                  <link rel="manifest" href="/manifest.json" />
                </Helmet>
                <CssBaseline />
                {!ready && <LaunchScreen />}
                {ready && (
                  <Router
                    user={user}
                    userData={userData}
                    performingAction={performingAction}
                    openSnackbar={openSnackbar}
                  />
                )}
                <Snackbar
                  autoHideDuration={snackbar.autoHideDuration}
                  message={snackbar.message}
                  open={snackbar.open}
                  onClose={closeSnackbar}
                />
              </SelectedUserProvider>
            </SelectedPlaceProvider>
          </CenterProvider>
        </NativePlacesProvider>
      </PlacesProvider>
    </MuiThemeProvider>
  );
};

export default App;
