import { default as React } from "react";
import {
  Theme,
  createStyles,
  makeStyles,
  Container,
  Box,
  Typography,
  Button,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import NativeIcon from "../../icons/NativeIcon";
import NativeCategoriesGroup from "../NativeCategoriesGroup";

export type CategoriesScreenProps = {};

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    box1: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flex: "3",
    },
    box2: {
      display: "flex",
      flexDirection: "column",
      flex: "4",
    },
    box3: {
      display: "flex",
      flexDirection: "column",
      flex: "1",
    },
    svgRoot: {
      height: theme.spacing(15),
      width: theme.spacing(15),
    },
    text: {
      textAlign: "center",
    },
    bottomText: {
      textAlign: "center",
      marginBottom: theme.spacing(1),
    },
    buttonPrimary: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      backgroundColor: "#f25f5c",
      borderRadius: theme.spacing(1),
    },
    buttonSecondary: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      backgroundColor: "#50514f",
      borderRadius: theme.spacing(1),
    },
  });

const useStyles = makeStyles(styles);

export const CategoriesScreen: React.FC<CategoriesScreenProps> = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Container className={classes.container}>
      <Box className={classes.box1}>
        <NativeIcon color="secondary" classes={{ root: classes.svgRoot }} />
      </Box>
      <Box className={classes.box2}>
        <Typography
          variant="h5"
          component="h1"
          className={classes.text}
          color="textSecondary"
          style={{ fontFamily: "EuropaBold" }}
        >
          Choose Places You Like
        </Typography>
        <Typography
          variant="body2"
          component="h1"
          className={classes.bottomText}
          color="textSecondary"
          style={{ fontFamily: "Europa" }}
        >
          Your Places will help us give you better results and recommendations
          when you explore Native
        </Typography>
        <NativeCategoriesGroup />
      </Box>
      <Box className={classes.box3}>
        <Typography
          variant="body2"
          component="h1"
          className={classes.text}
          color="textSecondary"
          style={{ fontFamily: "Europa", opacity: "80%" }}
        >
          You can adjust your preferences in your profile
        </Typography>
        <Button
          style={{ fontFamily: "EuropaBold" }}
          className={classes.buttonPrimary}
          variant="contained"
          color="secondary"
          size="large"
          onClick={() => {
            history.push("/getting-started");
          }}
        >
          Continue
        </Button>
      </Box>
    </Container>
  );
};

export default CategoriesScreen;
