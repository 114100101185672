import { default as React } from "react";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import Geosuggest, { Suggest } from "react-geosuggest";
import { useState, useContext } from "react";
import { Theme, createStyles, makeStyles, SvgIcon } from "@material-ui/core";

import PersonIcon from "@material-ui/icons/Person";
import authentication from "../../services/authentication";
import CenterContext from "../../contexts/CenterContext";
import SelectedPlaceContext from "../../contexts/SelectedPlaceContext";
import SettingsDialog from "../SettingsDialog";

import { grey } from "@material-ui/core/colors";
import PlacesApi from "../../services/places";
import CategoriesGroup from "../CategoriesGroup";
import { useLocation } from "react-router-dom";

interface SuggestFull extends Suggest {
  gmaps?: GeocoderResultFull;
  types?: string[];
}

interface GeocoderResultFull extends google.maps.GeocoderResult {
  name: string;
  opening_hours: google.maps.places.OpeningHours;
  price_level: number;
  rating: number;
}

interface MainAppBarProps {
  user: firebase.User | null;
  performingAction: boolean;
  userData: any;
  openSnackbar: Function;
}

const styles = (theme: Theme) =>
  createStyles({
    emptyStateIcon: {
      fontSize: theme.spacing(12),
    },

    suggestBox: {
      margin: 0,
      display: "flex",
      width: "100%",
    },

    suggestInput: {
      flex: "1",
    },

    button: {
      marginTop: theme.spacing(1),
    },

    buttonIcon: {
      marginRight: theme.spacing(1),
    },

    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
    },

    categoryToolbar: {
      maxWidth: "350px",
      display: "flex",
      flexFlow: "row wrap",
      paddingBottom: theme.spacing(1),
      margin: "0 auto",
    },

    categoryButtonDiv: {
      flex: "0 1 calc(20% - 4px)",
      margin: "4px 2px",
      display: "flex",
    },

    categoryButton: {
      backgroundColor: grey[200],
      padding: "3px",
      minWidth: "24px",
      border: "none",
      marginRight: "auto",
      marginLeft: "auto",
    },
  });

const useStyles = makeStyles(styles);

const MainAppBar: React.FC<MainAppBarProps> = ({
  user,
  userData,
  openSnackbar,
  ...props
}: MainAppBarProps) => {
  const [categoriesOpen, setCategoriesOpen] = useState(false);
  const location = useLocation();
  const [openSettings, setOpenSettings] = useState(
    location && location.state ? (location.state as any).newUser : false
  );
  const [autocompleteKey, setAutocompleteKey] = useState(0);
  const { center, setCenter } = useContext(CenterContext);
  const { setSelectedPlace } = useContext(SelectedPlaceContext);
  const classes = useStyles();

  const onSuggestSelect = async (option: SuggestFull) => {
    let place = await PlacesApi.getPlaceFromId(option.placeId);
    setCenter(place.position);
    setSelectedPlace(place);
    setAutocompleteKey(autocompleteKey + 1);
  };

  const toggleCategories = () => {
    setCategoriesOpen(!categoriesOpen);
  };

  const getAvatar = () => {
    if (!user) {
      return null;
    }

    const photoUrl = user.photoURL;

    if (photoUrl) {
      return <Avatar alt="Avatar" src={photoUrl} />;
    }

    if (!userData) {
      return <PersonIcon />;
    }

    const nameInitials = authentication.user.getNameInitials({
      ...user,
      ...userData,
    });

    if (nameInitials) {
      return <Avatar alt="Avatar">{nameInitials}</Avatar>;
    }

    return <PersonIcon />;
  };

  return (
    <>
      <AppBar color="primary" position="static">
        <Toolbar variant="regular">
          {user && (
            <>
              <Box flex={1}>
                <Geosuggest
                  key={autocompleteKey}
                  className={classes.suggestBox}
                  inputClassName={classes.suggestInput}
                  placeholder="Search"
                  maxFixtures={10}
                  radius={
                    20
                  } /* https://github.com/Microsoft/TypeScript/issues/27552#issuecomment-550566762
          this is needed because the type for geosuggest doesn't yet have the placeDetailFields
          // @ts-ignore */
                  placeDetailFields={[
                    "name",
                    "opening_hours",
                    "formatted_address",
                    "formatted_phone_number",
                    "website",
                    "place_id",
                    "price_level",
                    "types",
                    "geometry",
                    "rating",
                    "types",
                  ]}
                  onSuggestSelect={onSuggestSelect}
                  types={["establishment"]}
                  location={
                    new window.google.maps.LatLng(center.lat, center.lng)
                  }
                >
                  <IconButton
                    color={"default"}
                    disabled={props.performingAction}
                    onClick={toggleCategories}
                  >
                    <SvgIcon>
                      <path d="M2 16v2h20v-2H2zm0-5v2h20v-2H2zm0-5v2h20V6H2z" />
                    </SvgIcon>
                  </IconButton>
                </Geosuggest>
              </Box>
              <IconButton
                color={"default"}
                disabled={props.performingAction}
                onClick={toggleCategories}
              >
                <SvgIcon>
                  <path d="M2 16v2h20v-2H2zm0-5v2h20v-2H2zm0-5v2h20V6H2z" />
                </SvgIcon>
              </IconButton>
              <IconButton
                color="inherit"
                disabled={props.performingAction}
                onClick={() => setOpenSettings(true)}
              >
                {getAvatar()}
              </IconButton>
            </>
          )}
        </Toolbar>
        {user && categoriesOpen ? <CategoriesGroup /> : null}
      </AppBar>
      <SettingsDialog
        close={() => {
          setOpenSettings(false);
        }}
        open={openSettings}
        user={user as any}
        userData={userData}
        openSnackbar={openSnackbar}
      />
    </>
  );
};

export default MainAppBar;
