import { default as React, useState, useEffect } from "react";
import nativeCategories from "../../services/native-categories";
import {
  Toolbar,
  Button,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";

import LocalCafe from "../../icons/LocalCafe";
import TerrainIcon from "../../icons/TerrainIcon";
import ShoppingIcon from "../../icons/ShoppingIcon";
import BarIcon from "../../icons/BarIcon";
import CameraIcon from "../../icons/CameraIcon";
import ServicesIcon from "../../icons/ServicesIcon";
import DiningIcon from "../../icons/DiningIcon";
import EventsIcon from "../../icons/EventsIcon";
import MoviesIcon from "../../icons/MoviesIcon";
import HappyIcon from "../../icons/HappyIcon";

import { grey } from "@material-ui/core/colors";

export type NativeCategoriesGroupProps = {};

const styles = (theme: Theme) =>
  createStyles({
    emptyStateIcon: {
      fontSize: theme.spacing(12),
    },

    suggestBox: {
      margin: 0,
      display: "flex",
      width: "100%",
    },

    suggestInput: {
      flex: "1",
    },

    button: {
      marginTop: theme.spacing(1),
    },

    buttonIcon: {
      marginRight: theme.spacing(1),
    },

    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
    },

    nativeCategoryToolbar: {
      // maxWidth: "350px",
      minHeight: "128px",
      display: "flex",
      flexFlow: "row wrap",
      paddingBottom: theme.spacing(1),
      // margin: "0 auto"
    },

    nativeCategoryButtonDiv: {
      whiteSpace: "nowrap",
      flex: "0 1 calc(20% - 8px)",
      margin: "4px 2px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      fontSize: "x-small",
      textAlign: "center",
    },

    nativeCategoryButton: {
      backgroundColor: grey[200],
      padding: "3px",
      minWidth: "24px",
      border: "none",
      marginRight: "auto",
      marginLeft: "auto",
    },
  });

const useStyles = makeStyles(styles);

export const NativeCategoriesGroup: React.FC<NativeCategoriesGroupProps> = () => {
  const [catGroups, setCatGroups] = useState([] as Array<string>);

  useEffect(() => {
    const getCatGroups = async () => {
      let groups = await nativeCategories.getNativeCategoryGroupsForCurrentUser();
      if (!groups) groups = [];
      setCatGroups(groups);
    };
    getCatGroups();
    return () => {};
  }, []);

  const classes = useStyles();

  const onNativeCategoryGroupClicked = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const name = event.currentTarget.id;
    if (catGroups.includes(name)) {
      nativeCategories.setNativeCategoryGroupForUser(name, false);
      let newGroups = catGroups.filter((group) => {
        return group !== name;
      });
      setCatGroups(newGroups);
    } else {
      nativeCategories.setNativeCategoryGroupForUser(name, true);
      setCatGroups([...catGroups, name]);
    }
  };

  return (
    <Toolbar className={classes.nativeCategoryToolbar} variant="regular">
      <div className={classes.nativeCategoryButtonDiv}>
        {/* these buttons need to be componetized so that we don't have to use multiple magic strings */}
        <Button
          size="medium"
          variant="outlined"
          className={classes.nativeCategoryButton}
          id="localCafe"
          onClick={onNativeCategoryGroupClicked}
          color={catGroups.includes("localCafe") ? "secondary" : "default"}
        >
          <LocalCafe fontSize="large" />
        </Button>
        Cafe
      </div>
      <div className={classes.nativeCategoryButtonDiv}>
        <Button
          size="medium"
          variant="outlined"
          className={classes.nativeCategoryButton}
          id="outdoor"
          onClick={onNativeCategoryGroupClicked}
          color={catGroups.includes("outdoor") ? "secondary" : "default"}
        >
          <TerrainIcon fontSize="large" />
        </Button>
        Outdoor
      </div>
      <div className={classes.nativeCategoryButtonDiv}>
        <Button
          size="medium"
          variant="outlined"
          className={classes.nativeCategoryButton}
          id="shopping"
          onClick={onNativeCategoryGroupClicked}
          color={catGroups.includes("shopping") ? "secondary" : "default"}
        >
          <ShoppingIcon fontSize="large" />
        </Button>
        Shopping
      </div>
      <div className={classes.nativeCategoryButtonDiv}>
        <Button
          size="medium"
          variant="outlined"
          className={classes.nativeCategoryButton}
          id="bars"
          onClick={onNativeCategoryGroupClicked}
          color={catGroups.includes("bars") ? "secondary" : "default"}
        >
          <BarIcon fontSize="large" />
        </Button>
        Nightlife
      </div>
      <div className={classes.nativeCategoryButtonDiv}>
        <Button
          size="medium"
          variant="outlined"
          className={classes.nativeCategoryButton}
          id="sightseeing"
          onClick={onNativeCategoryGroupClicked}
          color={catGroups.includes("sightseeing") ? "secondary" : "default"}
        >
          <CameraIcon fontSize="large" />
        </Button>
        Sightseeing
      </div>
      <div className={classes.nativeCategoryButtonDiv}>
        <Button
          size="medium"
          variant="outlined"
          className={classes.nativeCategoryButton}
          id="services"
          onClick={onNativeCategoryGroupClicked}
          color={catGroups.includes("services") ? "secondary" : "default"}
        >
          <ServicesIcon fontSize="large" />
        </Button>
        Service
      </div>
      <div className={classes.nativeCategoryButtonDiv}>
        <Button
          size="medium"
          variant="outlined"
          className={classes.nativeCategoryButton}
          id="dining"
          onClick={onNativeCategoryGroupClicked}
          color={catGroups.includes("dining") ? "secondary" : "default"}
        >
          <DiningIcon fontSize="large" />
        </Button>
        Restaurants
      </div>
      <div className={classes.nativeCategoryButtonDiv}>
        <Button
          size="medium"
          variant="outlined"
          className={classes.nativeCategoryButton}
          id="events"
          onClick={onNativeCategoryGroupClicked}
          color={catGroups.includes("events") ? "secondary" : "default"}
        >
          <EventsIcon fontSize="large" />
        </Button>
        Events
      </div>
      <div className={classes.nativeCategoryButtonDiv}>
        <Button
          size="medium"
          variant="outlined"
          className={classes.nativeCategoryButton}
          id="movies"
          onClick={onNativeCategoryGroupClicked}
          color={catGroups.includes("movies") ? "secondary" : "default"}
        >
          <MoviesIcon fontSize="large" />
        </Button>
        Arts
      </div>
      <div className={classes.nativeCategoryButtonDiv}>
        <Button
          size="medium"
          variant="outlined"
          className={classes.nativeCategoryButton}
          id="personal"
          onClick={onNativeCategoryGroupClicked}
          color={catGroups.includes("personal") ? "secondary" : "default"}
        >
          <HappyIcon fontSize="large" />
        </Button>
        Personal Care
      </div>
    </Toolbar>
  );
};

export default NativeCategoriesGroup;
