import { default as React, useState, useEffect, useContext } from "react";
import { default as UserService, UserProfile } from "../../services/users";
import {
  Box,
  Avatar,
  Typography,
  Button,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import { storage } from "../../firebase";
import SelectedUserContext from "../../contexts/SelectedUserContext";

export type UserRowProps = {
  user: UserProfile;
  following: boolean;
  refresh: Function;
  close: Function;
};

const styles = (theme: Theme) =>
  createStyles({
    title: {
      color: "#1E1E1E",
      lineHeight: "1",
    },
    subTitle: {
      color: "#C3C3C3",
      lineHeight: "1",
      marginTop: "4px",
    },
  });

const useStyles = makeStyles(styles);

export const UserRow: React.FC<UserRowProps> = ({
  user,
  following,
  refresh,
  close,
}) => {
  const [avatarUrl, setAvatarUrl] = useState("");
  const { setSelectedUserId } = useContext(SelectedUserContext);
  const classes = useStyles();
  useEffect(() => {
    const getUrl = async () => {
      let url = await storage
        .ref("images/avatars/" + user.id)
        .getDownloadURL()
        .catch((reason) => {
          console.log("No Avatar");
        });
      setAvatarUrl(url);
    };
    getUrl();
  }, [user]);
  if (!user) return null;
  return (
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      justifyContent="space-between"
      paddingTop={1}
      paddingBottom={1}
      onClick={(e) => {
        setSelectedUserId(user.id);
        close();
      }}
    >
      <Avatar src={avatarUrl} />
      <Box flex="1" m={1}>
        <Typography className={classes.title} variant={"h6"}>
          {user.username}
        </Typography>
        <Typography className={classes.subTitle} variant={"body1"}>
          {user.firstName + " " + user.lastName}
        </Typography>
      </Box>
      {following ? (
        <Button
          color="secondary"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            UserService.setFollowerForUser(user.id, false).then(() =>
              refresh()
            );
          }}
        >
          Unfollow
        </Button>
      ) : (
        <Button
          color="secondary"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            UserService.setFollowerForUser(user.id, true).then(() => refresh());
          }}
        >
          Follow
        </Button>
      )}
    </Box>
  );
};

export default UserRow;
