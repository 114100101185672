import { default as React, Fragment } from "react";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Divider,
  Theme,
  createStyles,
  makeStyles,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { OpeningHours } from "../../services/places";

export type PlaceHoursProps = {
  times: OpeningHours;
};

const styles = (theme: Theme) =>
  createStyles({
    panel: {
      boxShadow: "unset",
      padding: "0 " + theme.spacing(1) + "px",
      width: "100%",
      "&$expanded": { margin: 0 },
    },
    expanded: {},
    details: {
      flexDirection: "column",
      padding: "unset",
    },
    divider: {
      width: "100%",
    },
    summary: {
      padding: "unset",
    },
    summaryTimeText: {
      fontSize: theme.typography.pxToRem(12),
      color: theme.palette.text.secondary,
    },
    timeText: {
      fontSize: theme.typography.pxToRem(12),
      padding: theme.spacing(1) + "px " + theme.spacing(3) + "px",
      color: theme.palette.text.secondary,
    },
  });

const useStyles = makeStyles(styles);

export const PlaceHours: React.FC<PlaceHoursProps> = ({ times }) => {
  const classes = useStyles();
  const now = moment();

  const todaysOpeningPeriod = times.periods.find(
    (period) => period.open.day === now.day()
  );
  let nextOpeningPeriod;

  for (let i = 0; i++; i < times.periods.length) {
    if (times.periods[(now.day() + 1 + i) % 7]) {
      nextOpeningPeriod = times.periods[(now.day() + 1 + i) % 7];
    }
  }

  const todaysOpen = todaysOpeningPeriod
    ? moment(todaysOpeningPeriod.open.time, "HHmm")
    : null;
  const todaysClose =
    todaysOpeningPeriod && todaysOpeningPeriod.close
      ? moment(todaysOpeningPeriod.close.time, "HHmm")
      : null;
  if (todaysClose?.isBefore(todaysOpen as moment.Moment)) {
    todaysClose.add(1, "day");
  }
  const nextOpen = nextOpeningPeriod
    ? moment(nextOpeningPeriod.open.nextDate, "HHmm")
    : null;
  const isOpen = todaysOpen
    ? todaysClose
      ? now.isBetween(todaysOpen, todaysClose)
      : now.isAfter(todaysOpen)
    : false;

  return (
    <ExpansionPanel
      classes={{ root: classes.panel, expanded: classes.expanded }}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon color={"secondary"} />}
        className={classes.summary}
      >
        {isOpen ? (
          <Fragment>
            <Typography
              style={{ display: "inline-block", color: "#109D57" }}
              variant={"body1"}
              className={classes.summaryTimeText}
            >
              Open
            </Typography>
            <Typography
              style={{ display: "inline-block" }}
              variant={"body1"}
              className={classes.summaryTimeText}
            >
              {todaysClose ? "· Closes " + todaysClose.format("h:mm a") : ""}
            </Typography>
          </Fragment>
        ) : (
          <Fragment>
            <Typography
              style={{ display: "inline-block", color: "#DE463C" }}
              variant={"body1"}
              className={classes.summaryTimeText}
            >
              Closed
            </Typography>
            <Typography
              style={{ display: "inline-block" }}
              variant={"body1"}
              className={classes.summaryTimeText}
            >
              {nextOpen ? "· Opens " + nextOpen.format("h:mm a") : ""}
            </Typography>
          </Fragment>
        )}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        {times.weekday_text.map((text) => {
          return (
            <React.Fragment>
              <Divider className={classes.divider} />
              <Typography className={classes.timeText}>{text}</Typography>
            </React.Fragment>
          );
        })}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default PlaceHours;
